import moment from 'moment'
import getCurrencySymbolFromCode from '../money/getCurrencySymbolFromCode'
import getFormattedDate from '../date/getFormattedDate'
import getFormattedMoney from '../money/getFormattedMoney'
import { customerInvoicesTableHeadingsData } from '../../constants/tableData'

const getCustomerInvoicesTableData = (data: any, timeZoneCode: string) => {
  if (!data) return

  const customerInvoicesTableBodyData = data?.map((item: any) => {
    const formattedDate = moment(getFormattedDate(item?.created_dtm, timeZoneCode))?.format('DD/MM/YYYY HH:mm:ss')

    return {
      invoiceId: item?.invoice_id ?? '',
      externalInvoiceId: item?.external_invoice_id ?? '',
      createdDtm: formattedDate ?? '',
      amount: `${getCurrencySymbolFromCode(item?.currency_cod)} ${getFormattedMoney(item?.total_dcm)}`,
      status: item?.status_cod ?? '',
      payoutId: item?.payout_id ?? '',
      companyId: item?.company_id
    }
  })

  const customerInvoicesTableData = {
    headings: customerInvoicesTableHeadingsData,
    body: customerInvoicesTableBodyData
  }

  return customerInvoicesTableData
}

export default getCustomerInvoicesTableData
