const validRoutesData = [
  '/',
  '/payout/batch',
  '/payout/statement',
  '/payout/customers',
  '/payout/customer-detail/:id/:companyId',
  '/payout/invoice-detail/:invoiceId/:companyId',
  '/payout/invoices'
]

export default validRoutesData
