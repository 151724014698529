import { useEffect, useState } from 'react'

import DropdownView from './DropdownView'

import { useOutsideClick } from '../../hooks/useOutsideClick'

import type { DropdownOptionType, DropdownOptionsType } from '../../../typings/types'

import './Dropdown.css'

interface IDropdownProps {
    options: DropdownOptionsType
    defaultOption?: DropdownOptionType
    size?: 'small' | 'regular' | 'large'
    value?: string
    isCentered?: boolean
    isFitContent?: boolean
    isDisabled?: boolean
    isDefaultOptionHide?: boolean
    onChange: (option: DropdownOptionType) => void
    optionKey?: keyof DropdownOptionType
};

const Dropdown = ({
  options,
  defaultOption,
  size = 'regular',
  value = '',
  isCentered = false,
  isFitContent = false,
  isDisabled = false,
  isDefaultOptionHide = false,
  optionKey,
  onChange = () => {}
}: IDropdownProps) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(defaultOption?.title)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const classNameOpen = isOpen ? 'dropdown--open' : ''
  const classNameCentered = isCentered ? 'dropdown--centered' : ''
  const classNameFitContent = isFitContent ? 'dropdown--fit' : ''
  const classNameDisabled = isDisabled ? 'dropdown--disabled' : ''
  const className = `dropdown dropdown--${size} ${classNameOpen} ${classNameCentered} ${classNameFitContent} ${classNameDisabled} actioncomponent`

  /*
     * Función que maneja la opción seleccionada del Dropdown
    */
  const handleSelectOption = (option: DropdownOptionType) => {
    const { title } = option
    const allOptions = defaultOption ? [...options, defaultOption] : options
    const optionSelected = allOptions.find(o => o.title === title)

    onChange(option)
    setCurrentValue(optionSelected?.title)
  }

  /*
     * Función que maneja la apertura del Dropdown al hacer click
    */
  const handleOpenDropdown = () => { setIsOpen(!isOpen) }

  /*
     * Función que cierra el Dropdown al dar un click fuera de el mismo
    */
  const dropdownRef = useOutsideClick(() => { setIsOpen(false) })

  /*
     * Efecto que asigna el valor actual al Dropdown
    */
  useEffect(() => {
    setCurrentValue(!value ? currentValue : value)
    }, [value]); // eslint-disable-line

  /*
     * Renderizado del componente Dropdown
    */
  if (!options?.length) return <></>

  return (
    <DropdownView
      className={className}
      dropdownRef={dropdownRef}
      options={options}
      defaultOption={defaultOption}
      value={currentValue}
      isDefaultOptionHide={isDefaultOptionHide}
      handleOpenDropdown={handleOpenDropdown}
      handleSelectOption={handleSelectOption}
      optionKey={optionKey}
    />
  )
}

export default Dropdown
