export const statementTableHeadingsData = [
  {
    id: 1,
    title: 'Fecha y hora',
    style: 'strong',
    isLink: false
  },
  {
    id: 2,
    title: 'Compañía',
    style: 'regular',
    isLink: false
  },
  {
    id: 3,
    title: 'Tipo',
    style: 'regular',
    isLink: false
  },
  {
    id: 4,
    title: 'Descripción',
    style: 'regular',
    isLink: false,
    isTruncate: true
  },
  {
    id: 5,
    title: 'Nombre proceso',
    style: 'regular',
    isLink: false
  },
  {
    id: 6,
    title: 'Moneda',
    style: 'regular',
    isLink: false
  },
  {
    id: 7,
    title: 'Monto',
    style: 'regular',
    isLink: false
  },
  {
    id: 8,
    title: 'Fondo disponible',
    style: 'regular',
    isLink: false
  }
]

export const customersTableHeadingsData = [
  {
    id: 1,
    title: 'Id Cliente OB',
    style: 'strong',
    isLink: true
  },
  {
    id: 2,
    title: 'Compañía',
    style: 'regular',
    isLink: false
  },
  {
    id: 3,
    title: 'Id Cliente',
    style: 'regular',
    isLink: true
  },
  {
    id: 4,
    title: 'Nombres',
    style: 'regular',
    isLink: true
  },
  {
    id: 5,
    title: 'Tipo documento',
    style: 'regular',
    isLink: true
  },
  {
    id: 6,
    title: '# Documento',
    style: 'strong',
    isLink: true
  }
]

export const customerInvoicesTableHeadingsData = [
  {
    id: 1,
    title: 'Id OB',
    style: 'strong',
    isLink: true
  },
  {
    id: 2,
    title: 'Id movimiento',
    style: 'regular',
    isLink: true
  },
  {
    id: 3,
    title: 'Fecha',
    style: 'regular',
    isLink: true
  },
  {
    id: 4,
    title: 'Monto',
    style: 'regular',
    isLink: false
  },
  {
    id: 5,
    title: 'Estado',
    style: 'badge',
    isLink: false
  },
  {
    id: 6,
    title: 'Proceso nombre',
    style: 'regular',
    isLink: true
  }
]

export const invoicesTableHeadingsData = [
  {
    id: 1,
    title: 'Id OB',
    style: 'strong',
    isLink: true
  },
  {
    id: 2,
    title: 'Compañía',
    style: 'regular',
    isLink: true
  },
  {
    id: 3,
    title: 'Id movimiento',
    style: 'regular',
    isLink: true
  },
  {
    id: 4,
    title: 'Fecha',
    style: 'regular',
    isLink: true
  },
  {
    id: 5,
    title: 'Monto',
    style: 'regular',
    isLink: true
  },
  {
    id: 6,
    title: 'Estado',
    style: 'badge',
    isLink: false
  },
  {
    id: 7,
    title: 'Banco',
    style: 'regular',
    isLink: true
  }
]
