import { useState, useEffect } from 'react'
import CardToolbarView from './CardToolbarView'
import { type PayoutDataType } from '../../../../typings/types'

interface IToolbarProps {
    timeZoneCode: string
    isDispersing?: boolean
    isDisabled?: boolean
    onClick?: any
    payoutsOriginCode?: PayoutDataType['origin_cod']
    payoutsStatusCode?: string
};

const Toolbar = ({
  isDispersing = false,
  isDisabled = false,
  onClick = () => {},
  payoutsOriginCode,
  payoutsStatusCode
}: IToolbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFileProcessActive, setIsFileProcessActive] = useState<boolean>(true)
  const isFromApi = payoutsOriginCode === 'API'

  /*
     * Función que maneja el click al abrir el Toolbar
    */
  const handleOpenToolbar = (isOpen: boolean) => { setIsOpen(isOpen) }

  /*
     * Función que maneja los eventos que suceden al elegir una opción del toolbar
    */
  const handleSelectToolbarOption = (event: any) => {
    event?.preventDefault()
    setIsOpen(false)
    onClick(event)
  }

  useEffect(() => {
    const isFileProcessActive = payoutsStatusCode === 'WAITING'

    setIsFileProcessActive(isFileProcessActive)
  }, [payoutsStatusCode])

  if (!payoutsStatusCode || !payoutsOriginCode) return <></>

  return (
    <CardToolbarView
      isOpen={isOpen}
      isDisabled={isDisabled}
      isDispersing={isDispersing}
      isFromApi={isFromApi}
      isFileProcessActive={isFileProcessActive}
      handleOpenToolbar={handleOpenToolbar}
      handleSelectToolbarOption={handleSelectToolbarOption}
    />
  )
}

export default Toolbar
