import { useAppSelector } from '../../../store/hooks'
import getFormattedGMTDate from '../../../utils/date/getFormattedGMTDate'

export const useFooter = () => {
  const company = useAppSelector((state) => state.authReducer.company)
  const now = company && company.length === 1 ? getFormattedGMTDate(new Date(), company[0].time_zone_cod) : new Date()
  const currentYear = now.getFullYear()

  return {
    now,
    currentYear
  }
}
