
import BatchView from './components/BatchView'

import './Batch.css'
import useBatch from './hooks/useBatch'

const Batch = () => {
  const {
    timeZoneCode,
    uploadedFile,
    fileBatchList,
    uploadedFileStatus,
    // newPayoutId,
    // currentPayout,
    isActiveUploadButton,
    templateExtensions,
    templateList,
    // uploadFileIdSelected,
    fileBatchListIsLoading,
    filterData,
    loadingToastList,
    batchToastList,
    toastClassName,
    canViewToast,
    selectedPayouts,
    fetchedPayouts,
    isFetchingPayout,
    // setCurrentPayout,
    // setIsDispersionStateLoading,
    setSelectedPayouts,
    setIsFetchingPayout,
    setAreLoadingPayoutsList,
    handleChangeUploadFile,
    handleChangeSelectTemplate,
    handleClickCloseUploadFileStatus,
    handleClickUploadFile,
    handleScrollUploadFiles,
    // handleDispersionLoadingDone,
    reloadBatchDispersionState,
    handleFilterSubmit
  } = useBatch()

  /*
     * Renderizado de la vista Batch
    */
  return (
    <BatchView
      canViewToast={canViewToast}
      toastClassName={toastClassName}
      loadingToastList={loadingToastList}
      batchToastList={batchToastList}
      timeZoneCode={timeZoneCode}
      uploadedFile={uploadedFile}
      templateExtensions={templateExtensions}
      templateList={templateList}
      fileBatchList={fileBatchList}
      uploadedFileStatus={uploadedFileStatus}
      // uploadFileIdSelected={uploadFileIdSelected}
      reloadBatchDispersionState={reloadBatchDispersionState} // eslint-disable-line
      // newPayoutId={newPayoutId}
      fileBatchListIsLoading={fileBatchListIsLoading}
      isActiveUploadButton={isActiveUploadButton}
      // currentPayout={currentPayout}
      filterData={filterData}
      selectedPayouts={selectedPayouts}
      fetchedPayouts={fetchedPayouts}
      isFetchingPayout={isFetchingPayout}
      // setCurrentPayout={setCurrentPayout}
      // setIsDispersionStateLoading={setIsDispersionStateLoading}
      setSelectedPayouts={setSelectedPayouts}
      setIsFetchingPayout={setIsFetchingPayout}
      setAreLoadingPayoutsList={setAreLoadingPayoutsList}
      handleChangeUploadFile={handleChangeUploadFile} // eslint-disable-line
      handleChangeSelectTemplate={handleChangeSelectTemplate}
      handleClickCloseUploadFileStatus={handleClickCloseUploadFileStatus}
      handleClickUploadFile={handleClickUploadFile} // eslint-disable-line
      handleScrollUploadFiles={handleScrollUploadFiles}
      // handleDispersionLoadingDone={handleDispersionLoadingDone}
      handleFilterSubmit={handleFilterSubmit}
    />
  )
}

export default Batch
