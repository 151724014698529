import type { DropdownOptionType } from '../../../typings/types'
import { type Company } from '../../store/slices/auth'
import removeSpaces from '../commons/removeSpaces'

const getTemplateOptionsData = (companies: Company[] | null) => {
  if (!companies) return []

  const templates: DropdownOptionType[] = []
  companies.forEach(company => {
    const { company_id, files } = company

    files.forEach(file => {
      const { file_cod } = file
      const name = removeSpaces(file_cod).toLowerCase()

      templates.push({
        id: templates.length,
        name,
        title: file_cod,
        companyId: company_id
      })
    })
  })

  return templates
}

export default getTemplateOptionsData
