import Badge from '../Badge/Badge'
import Loader from '../Loader/Loader'
import BatchDispersionStateLoader from '../Loader/BatchDispersionStateLoader/BatchDispersionStateLoader'

import processStatusData from '../../constants/processStatusData'

import './Table.css'

interface ITableProps {
    data: any
    tableRef?: any
    isLoadingTableData?: boolean
    handleScrollTableData?: React.UIEventHandler<HTMLTableSectionElement> | undefined
    handleTableDataClick?: any
    visibleBodyData?: string[]
};

const Table = ({
  data,
  tableRef,
  isLoadingTableData = true,
  handleScrollTableData = () => {},
  handleTableDataClick = () => {},
  visibleBodyData
}: ITableProps) => {
  if (!data) return <></>

  /**
   * Truncate text if it's longer than the specified length
   * @param text
   * @param length
   * @returns
   */
  const truncateText = (text: string, length: number) => {
    if (text?.length > length) {
      return `${text.substring(0, length)}...`
    }
    return text
  }

  return (
    <div ref={tableRef} className="wrapper_table" onScroll={handleScrollTableData}>
      <table className="table">
        <thead>
          <tr>
            {
              data?.headings?.map((heading: any) => (
                <th key={heading?.id}>
                  {heading?.title?.toUpperCase()}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            (isLoadingTableData && !data?.body?.length)
              ? (
                <tr>
                  <td colSpan={data?.headings?.length}>
                    <BatchDispersionStateLoader />
                  </td>
                </tr>
              )
              : data?.body?.map((item: any, itemIdx: number) => {
                const currentBadge = processStatusData?.find((process: any) => process?.name?.toUpperCase() === item?.status?.toUpperCase() || process?.code?.toUpperCase() === item?.status?.toUpperCase())
                const badgeText = currentBadge?.altTitle
                const badgeColor = currentBadge?.color
                const filteredItemKeys = visibleBodyData ? Object.keys(item).filter(key => visibleBodyData.includes(key)) : Object.keys(item)

                return (
                  <tr key={`tableRow--${itemIdx}`}>
                    {
                      filteredItemKeys.map((tableKey, tableDataIdx: number) => {
                        const tableData = item[tableKey]
                        const tableDataStyle = data?.headings?.[tableDataIdx]?.style
                        const isTableDataLink = data?.headings?.[tableDataIdx]?.isLink

                        return (
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          <td key={`tableData--${itemIdx}--${tableDataIdx}`} className={`tableData--${tableDataStyle}`}>
                            <Loader isLoading={isLoadingTableData}>
                              {
                                tableDataStyle === 'badge'
                                  ? (
                                    <Badge
                                      text={badgeText}
                                      size="large"
                                      color={badgeColor}
                                    />
                                  )
                                  : isTableDataLink
                                    ? (
                                      <div
                                        className="tableDataLink"
                                        onClick={() => handleTableDataClick(item)}
                                      >
                                        {tableData}
                                      </div>
                                    )
                                    : (
                                      <>{
                                        data?.headings?.[tableDataIdx]?.isTruncate
                                          ? truncateText(tableData, 20)
                                          : tableData
                                      }</>
                                    )
                              }
                            </Loader>
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
          }
        </tbody>
      </table>
    </div>
  )
}

export default Table
