import './Code.css'

interface ICodeProps {
    label?: string
    text?: string
};

const Code = ({ label, text }: ICodeProps) => {
  if (!label || !text || label.length === 0 || text.length === 0) return null

  return (
    <div className="code">
      <label className="code__label">{label}</label>
      <code className="code__text">{text}</code>
    </div>
  )
}

export default Code
