import { useAppSelector } from '../../../../store/hooks'
import { useContext, useState } from 'react'
import moment from 'moment'

import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import approveProcessService from '../../../../services/payoutServices/approveProcessService'
import bankBatchService from '../../../../services/payoutServices/bankBatchService'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getTimeZoneHours from '../../../../utils/date/getTimeZoneHours'
import getUtcOffHours from '../../../../utils/date/getUtcOffHours'
import ModalContext from '../../../../contexts/ModalContext'
// import processStatusService from '../../../../services/payoutServices/processStatusService'
import transactionReportService from '../../../../services/payoutServices/transactionReportService'
import transactionStatusService from '../../../../services/payoutServices/transactionStatusService'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import voidProcessService from '../../../../services/payoutServices/voidProcessService'

// import { InitialPayoutData } from '../../../../constants/initialData'
import type { TBankSelected, BatchProcessStatusType, PayoutDataType, PayoutDataEventType } from '../../../../../typings/types'
import bankCodes from '../../../../constants/bankCodes'
import { downloadDataAsFile } from '../../../../utils/data/downloadDataAsFile'
import { useBasePath } from '../../../../hooks'
import { getPermittedComponents } from '../../../../utils/permissions/getPermittedComponents'
import { useLocation } from 'react-router-dom'

// const banks = [
//   {
//     id: bankCodes.BCP,
//     name: 'BCP'
//   },
//   {
//     id: bankCodes.BBVA,
//     name: 'BBVA'
//   },
//   {
//     id: bankCodes.INTERBANK,
//     name: 'Interbank'
//   },
//   {
//     id: bankCodes.SCOTIABANK,
//     name: 'Scotiabank'
//   },
//   {
//     id: bankCodes.OTHERS,
//     name: 'BBVA-CCI'
//   }
// ]

const banks = {
  [bankCodes.BCP]: 'BCP',
  [bankCodes.BBVA]: 'BBVA',
  [bankCodes.INTERBANK]: 'Interbank',
  [bankCodes.SCOTIABANK]: 'Scotiabank',
  [bankCodes.OTHERS]: 'BBVA-CCI'
} as const

interface Props {
  timeZoneCode: string
  // setCurrentPayout: any
  // currentPayout: PayoutDataType
  // selectedPayouts: Record<string, PayoutDataType>
  fetchedPayouts: Record<string, PayoutDataType>
  setIsDispersionStateLoading: any
  setIsFetchingPayout: React.Dispatch<React.SetStateAction<boolean>>
  setAreLoadingPayoutsList: React.Dispatch<React.SetStateAction<boolean>>
  reloadBatchDispersionState: () => void
  // handleDispersionLoadingDone: (option: boolean) => void
}

const useBatchDispersionState = ({
  timeZoneCode,
  // currentPayout,
  // setCurrentPayout,
  // selectedPayouts,
  fetchedPayouts,
  setIsDispersionStateLoading,
  setIsFetchingPayout,
  setAreLoadingPayoutsList,
  reloadBatchDispersionState
  // handleDispersionLoadingDone
}: Props) => {
  const basePath = useBasePath()
  const { pathname } = useLocation()
  const {
    user: reduxUser,
    services: servicesRedux,
    servicesBySubMenu: servicesBySubMenuRedux,
    components: componentsRedux
  } = useAppSelector(state => state.authReducer)
  // const [payoutDataSelected] = useState<PayoutDataType>(InitialPayoutData)
  // const [fetchedPayouts, setFetchedPayouts] = useState<PayoutDataType[]>([])
  const [fileNameSelected] = useState('')
  const [processStatus] = useState<BatchProcessStatusType>('PROCESSING')
  const [bankSelected, setBankSelected] = useState<TBankSelected>('all')
  const [transactionReportsOptions] = useState<any>([])
  const [isLoading] = useState(true)
  const [isProcess] = useState(false)
  const [isOpenTransactionReports, setIsOpenTransactionReports] = useState(false)
  const [statusHistoryTimelineOpened, setStatusHistoryTimelineOpened] = useState(false)
  const { setModalData } = useContext(ModalContext)

  const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')
  const timeZoneHours = getTimeZoneHours(timeZoneCode)
  const utcOffHours = getUtcOffHours(timeZoneHours)

  const permittedComponentsByView = getPermittedComponents(componentsRedux?.allComponents, componentsRedux?.payoutCustomersComponents, pathname, reduxUser?.role_id ?? '')
  const statusHistoryComponent = permittedComponentsByView?.find((permittedComponent: any) => permittedComponent?.component_str === 'batch-dispersion-status-history-subtitle')
  const statusHistoryComponentPermissions = statusHistoryComponent?.permissions
  const canViewStatusHistory = statusHistoryComponentPermissions?.length > 0
  const hasPayouts = Object.keys(fetchedPayouts).length > 0
  const payoutIds = Object.values(fetchedPayouts).map(payout => payout.payout_id).join(',')

  /*
     * Función que maneja la petición de servicio del proceso de estado de dispersión de un archivo
    */
  // const setFileBatchProcessStatus = async () => {
  //   if (validPermittedServices('processStatus', permissions)) {
  //     try {
  //       const response = await processStatusService(currentPayout, reduxUser)
  //       console.log({
  //         response,
  //         selectedPayouts
  //       })
  //       const { data } = response

  //       if (data === 'OK') {
  //         setPayoutDataSelected(InitialPayoutData)
  //         setCurrentPayout(InitialPayoutData)
  //       } else {
  //         setProcessStatus(data?.status_cod)
  //         setPayoutDataSelected(data)
  //         setCurrentPayout(data)
  //       };

  //       setIsLoading(false)
  //     } catch (error: any) {
  //       setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
  //     };
  //   } else {
  //     setModalData({ isOpen: true, type: 'serviceError', service: 'processStatus' })
  //   }

  //   setIsLoading(false)
  // }

  /*
     * Función que detiene el intervalo que llama a la petición de servicio del proceso de estado de dispersión.
    */
  const handleClickSelectExport = async (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    if (!hasPayouts) return
    const { id } = event?.currentTarget

    let response: any

    if (id === 'reports') {
      if (validPermittedServices('transactionStatus', permissions)) {
        try {
          response = await transactionStatusService(payoutIds, reduxUser)

          downloadDataAsFile({
            data: response.data,
            date: response.headers.date,
            format: 'xlsx',
            name: 'transaction-status',
            utcOffHours
          })
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'transactionStatus' })
      }
    } else if (id === 'process-approve') {
      if (!validPermittedServices('approveProcess', permissions)) {
        setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess' })
        return
      }
      try {
        setIsFetchingPayout(true)
        setAreLoadingPayoutsList(true)
        const response = await approveProcessService(payoutIds, reduxUser)

        const { status } = response

        if (status === 200) {
          reloadBatchDispersionState()
        } else if (status === 402) {
          setIsFetchingPayout(false)
          setAreLoadingPayoutsList(false)
          const errorMessage = response?.data?.service?.message_str

          setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess', message: errorMessage })
        } else {
          setIsFetchingPayout(false)
          setAreLoadingPayoutsList(false)
          setModalData({ isOpen: true, type: 'serviceError', service: 'approveProcess', message: 'Problemas de conexión, intentar nuevamente' })
        };
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      }
    } else if (id === 'process-void') {
      if (!validPermittedServices('voidProcess', permissions)) {
        setModalData({ isOpen: true, type: 'serviceError', service: 'voidProcess' })
        return
      }
      try {
        setIsFetchingPayout(true)
        setAreLoadingPayoutsList(true)
        await voidProcessService(payoutIds, reduxUser)

        reloadBatchDispersionState()
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      }
    } else if (id === 'upload-file') {
      const filePaths = Object.values(fetchedPayouts).map(payout => payout?.batch?.file?.file_path).filter(Boolean) as string[]
      filePaths.forEach(filePath => window.open(filePath, '_blank'))
    } else {
      if (validPermittedServices('bankBatch', permissions)) {
        try {
          const bank: any = {
            all: 'all',
            bcp: bankCodes.BCP,
            bbva: bankCodes.BBVA,
            ibk: bankCodes.INTERBANK,
            sco: bankCodes.SCOTIABANK,
            otr: bankCodes.OTHERS
          }

          const response = await bankBatchService(payoutIds, 'txt', 'zip', bank?.[id], reduxUser)

          const { data } = response

          const uploadFileData = JSON.parse(new TextDecoder().decode(data as ArrayBuffer))

          const { path } = uploadFileData?.files?.[id]

          window.open(path, '_blank')
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'bankBatch' })
      }
    };
  }

  /*
     * Función que maneja el click al seleccionar un banco
    */
  const handleClickSelectBank = (bankName: TBankSelected) => {
    setBankSelected(bankName)
  }

  // const handleTransactionReportOptions = (paidBanks: any) => {
  //   if (!paidBanks) return

  //   const banks = [
  //     {
  //       id: bankCodes.BCP,
  //       name: 'BCP'
  //     },
  //     {
  //       id: bankCodes.BBVA,
  //       name: 'BBVA'
  //     },
  //     {
  //       id: bankCodes.INTERBANK,
  //       name: 'Interbank'
  //     },
  //     {
  //       id: bankCodes.SCOTIABANK,
  //       name: 'Scotiabank'
  //     },
  //     {
  //       id: bankCodes.OTHERS,
  //       name: 'BBVA-CCI'
  //     }
  //   ]

  //   const transactionReportsBanksArr = Object?.entries(paidBanks)

  //   const filteredTransactionReportsBanks = transactionReportsBanksArr?.filter((entry: any) => {
  //           const [_, value] = entry; // eslint-disable-line

  //     if (!value?.operation_cod || value?.operation_cod === '') {
  //       return false
  //     };

  //     if (!value?.['file-status']) {
  //       return false
  //     };

  //     if (!value?.['file-status']?.file_path || value?.['file-status']?.file_path === '') {
  //       return false
  //     };

  //     return true
  //   })

  //   const transactionReportsOptions = filteredTransactionReportsBanks?.map((currentBank: any, idx: number) => {
  //     const bankName = banks?.find((bank: any) => bank?.id === currentBank?.[0])?.name

  //     return {
  //       id: idx,
  //       name: bankName,
  //       title: bankName,
  //       value: currentBank?.[1]?.['file-status']?.file_path
  //     }
  //   })

  //   setTransactionReportsOptions(transactionReportsOptions)
  // }

  const getTransactionReportOptions = () => {
    const fetchPayoutsIds = Object.keys(fetchedPayouts)
    const fetchedPayoutsSize = fetchPayoutsIds.length
    if (fetchedPayoutsSize === 0) return []

    // use the first paid banks
    const firstPayout = fetchedPayouts[fetchPayoutsIds[0]]
    let paidBanks: PayoutDataEventType | undefined
    if (!firstPayout.origin_cod) return []
    if (firstPayout.origin_cod === 'API') {
      paidBanks = firstPayout?.events?.all?.paid
    } else if (firstPayout.origin_cod === 'BATCH') {
      paidBanks = firstPayout?.events?.paid
    }
    if (!paidBanks) return []

    const transactionReportsBanksArr = Object.entries(paidBanks)
    const filteredTransactionReportsBanks = transactionReportsBanksArr.filter(entry => {
      const value = entry[1]

      if (!value?.operation_cod || value?.operation_cod === '') return false
      if (!value?.['file-status']) return false
      if (!value?.['file-status']?.file_path || value?.['file-status']?.file_path === '') return false

      return true
    })

    const transactionReportsOptions = filteredTransactionReportsBanks.map((currentBank, idx: number) => {
      const value = currentBank[1]
      const bankId = currentBank[0] as keyof typeof banks
      const bankName = banks[bankId]

      return {
        id: idx,
        name: bankName,
        title: bankName,
        value: value?.['file-status']?.file_path
      }
    })

    return transactionReportsOptions
  }

  const handleClickTransactionReport = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    if (transactionReportsOptions?.length) {
      setIsOpenTransactionReports(!isOpenTransactionReports)
    } else {
      handleCreateTransactionReport() // eslint-disable-line
    };
  }

  const handleClickTransactionReportsBanks = (option: string) => {
    handleCreateTransactionReport(option) // eslint-disable-line
  }

  /*
     * Función que maneja el click del botón de creación del reporte de transacción
    */
  const handleCreateTransactionReport = async (url: string = '') => {
    if (validPermittedServices('transactionReport', permissions)) {
      if (!hasPayouts) return
      try {
        let currentUrl: string
        const date = new Date()
        const momentDate = moment(date)?.utcOffset(utcOffHours)?.format('DDMMYYYYHHmmss')

        if (url === '') {
          const response = await transactionReportService(payoutIds, reduxUser)

          const { data } = response
          currentUrl = window?.URL?.createObjectURL(new Blob([data]))
        } else {
          currentUrl = url
        };

        const link = document?.createElement('a')

        link.setAttribute('href', currentUrl)
        link.setAttribute('target', '_blank')
        link.setAttribute('download', `transaction-report-${momentDate}.pdf`)

        document?.body?.appendChild(link)

        link?.click()
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'transactionReport' })
    }
  }

  const handleClickStatusHistoryTrigger = () => {
    setStatusHistoryTimelineOpened(!statusHistoryTimelineOpened)
  }

  const transactionReportsRef = useOutsideClick(() => { setIsOpenTransactionReports(false) })

  /*
     * Efecto que dispara los eventos cuando se selecciona un archivo subido de la cola
    */
  // useEffect(() => {
  //   if (currentPayout.payout_id === '') {
  //     setPayoutDataSelected(InitialPayoutData)
  //     setBankSelected('')
  //     setIsProcess(false) // Si se deselecciona un archivo de la lista, se mata el bucle
  //     setIsLoading(true)

  //     return
  //   };

  //   setPayoutDataSelected(InitialPayoutData)
  //   setBankSelected('Todos')
  //   setIsProcess(false)
  //   setIsLoading(true)

  //   setTimeout(() => {
  //     setIsProcess(true)
  //   }, 500)
  // }, [currentPayout.payout_id])

  // /*
  //    * Efecto que respecto a la data del archivo seleccionado de la cola, setea el nombre de este y que además
  //    * maneja las opciones de bancos para la creación del reporte de transacción
  //   */
  // useEffect(() => {
  //   if (!payoutDataSelected) return

  //   handleTransactionReportOptions(payoutDataSelected?.events?.paid?.banks)

  //   setFileNameSelected(payoutDataSelected?.name_str)
  // }, [payoutDataSelected, timeZoneCode])

  // /*
  //    * Efecto que maneja si el componente de dispersión está cargando y lo manda
  //    * al padre "Batch" para su uso
  //   */
  // useEffect(() => {
  //   if (!isLoading) {
  //     handleDispersionLoadingDone(true)
  //   };
  //   }, [isLoading]); // eslint-disable-line

  /*
     * Efecto que dispara la petición de servicio en intervalos de 5 segundos
    */
  // useEffect(() => {
  //   let interval: NodeJS.Timeout | undefined

  //   if (isProcess) {
  //     if (processStatus === 'PROCESSING' || processStatus === 'CHECKING') {
  //       setFileBatchProcessStatus() // eslint-disable-line
  //       // eslint-disable-next-line
  //               interval = setInterval(() => {
  //         setFileBatchProcessStatus() // eslint-disable-line
  //       }, 5000)
  //     };

  //     if (processStatus === 'DISPERSION') {
  //       interval = setInterval(() => {
  //         setFileBatchProcessStatus() // eslint-disable-line
  //       }, 300000)
  //     };
  //   } else {
  //     setProcessStatus('PROCESSING')

  //     clearInterval(interval)
  //   };

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [isProcess, processStatus])

  return {
    // States
    fileNameSelected,
    bankSelected,
    transactionReportsRef,
    processStatus,
    transactionReportsOptions: getTransactionReportOptions(),
    isLoading,
    isProcess,
    isOpenTransactionReports,
    statusHistoryTimelineOpened,
    canViewStatusHistory,

    // Functions States

    // Functions
    handleClickSelectExport,
    handleClickSelectBank,
    handleClickTransactionReport,
    handleClickTransactionReportsBanks,
    handleClickStatusHistoryTrigger
  }
}

export default useBatchDispersionState
