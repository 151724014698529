const validServicesData: any = {
  approveProcess: {
    service: 'ob-sl-kuraka',
    endPoint: 'v1/payout/{payout_id}/status',
    method: 'PATCH'
  },
  bankBatch: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/{payout_id}/bank-batch',
    method: 'GET'
  },
  customersData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/customers',
    method: 'GET'
  },
  customerDetailData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/customer-detail',
    method: 'GET'
  },
  statementData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/statement',
    method: 'GET'
  },
  statementDetailData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/statement-detail',
    method: 'GET'
  },
  invoiceBatch: {
    service: 'ob-ms-maqaqkuna',
    endPoint: 'v1/payout/invoice-batch',
    method: 'POST'
  },
  invoicesData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/invoices',
    method: 'GET'
  },
  invoiceDetailData: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/invoice-detail',
    method: 'GET'
  },
  listFileBatch: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/list-file-batch',
    method: 'GET'
  },
  processStatus: {
    service: 'ob-sl-kuraka',
    endPoint: 'v1/payout/{payout_id}/process-status',
    method: 'POST'
  },
  transactionReport: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/{payout_id}/transaction-report',
    method: 'GET'
  },
  transactionStatus: {
    service: 'ob-ms-quipu',
    endPoint: 'v1/payout/{payout_id}/transaction-status',
    method: 'GET'
  },
  voidProcess: {
    service: 'ob-sl-kuraka',
    endPoint: 'v1/payout/{payout_id}',
    method: 'DELETE'
  }
}

export default validServicesData
