import BatchUploadFile from './BatchUploadFile/BatchUploadFile'
import Card from '../../../../../components/Card/Card'
import Dropdown from '../../../../../components/Dropdown/Dropdown'
import Icon from '../../../../../components/Icon/Icon'
import Loader from '../../../../../components/Loader/Loader'
import PermittedComponent from '../../../../../components/PermittedComponent/PermittedComponent'
import UploadButton from '../../../../../components/Button/UploadButton/UploadButton'

// import useBatchUpload from '../../hooks/useBatchUpload'
import type { PayoutDataType, DropdownOptionsType, TUploadedFileStatus, TBatchPayoutData, DropdownOptionType } from '../../../../../../typings/types'

import './BatchUpload.css'
import getStringFromArray from '../../../../../utils/commons/getStringFromArray'

const uploadedFileStatusMessages = {
  already: 'El archivo ya fue cargado anteriormente',
  userPermitError: 'El usuario no tiene permisos para realizar esta acción',
  extensionError: 'El formato del archivo no está permitido',
  error: 'No se pudo cargar el archivo, intentar nuevamente en unos minutos'
} as const

const getUploadedFileStatusMessage = ({
  uploadedFileName,
  uploadedFileSize,
  uploadedFileStatus
}: {
  uploadedFileName: string
  uploadedFileSize: number
  uploadedFileStatus?: TUploadedFileStatus
}) => {
  if (!uploadedFileStatus) return ''
  if (uploadedFileStatus === 'success') return `${uploadedFileName} (${uploadedFileSize?.toFixed(1)} KB)`
  return uploadedFileStatusMessages[uploadedFileStatus]
}

interface IBatchUploadProps {
    timeZoneCode: string
    uploadedFile?: File
    uploadedFileStatus?: TUploadedFileStatus
    templateExtensions: string[]
    fileBatchList: TBatchPayoutData[]
    fileBatchListIsLoading: boolean
    templateList: DropdownOptionsType
    isActiveUploadButton: boolean
    // fetchedPayouts: Record<string, PayoutDataType>
    // currentPayout: PayoutDataType
    filterData: any
    selectedPayouts: Record<string, PayoutDataType>
    handleChangeUploadFile: React.ChangeEventHandler<HTMLInputElement> | undefined
    handleChangeSelectTemplate: (option: DropdownOptionType) => void
    handleClickCloseUploadFileStatus: React.MouseEventHandler<HTMLSpanElement> | undefined
    handleClickUploadFile: (payout: PayoutDataType) => void
    handleScrollUploadFiles: (event: React.UIEvent<HTMLDivElement, UIEvent>) => Promise<void>
    handleFilterSubmit: any
};

const BatchUpload = ({
  timeZoneCode,
  uploadedFile,
  uploadedFileStatus,
  templateExtensions,
  templateList,
  isActiveUploadButton,
  fileBatchList,
  fileBatchListIsLoading,
  selectedPayouts,
  filterData,
  // fetchedPayouts,
  handleChangeUploadFile,
  handleChangeSelectTemplate,
  handleClickCloseUploadFileStatus,
  handleClickUploadFile,
  handleScrollUploadFiles,
  handleFilterSubmit
}: IBatchUploadProps) => {
  const classNameUploadFileStatusMessage = `batchupload__status__text batchupload__status__text--${uploadedFileStatus ?? ''}` // eslint-disable-line
  const uploadedFileName = uploadedFile ? uploadedFile.name : ''
  const uploadedFileSize = uploadedFile ? uploadedFile.size / 1024 : 0
  const dropdownDefaultOption = { id: templateList.length, name: 'template', title: 'Plantilla' }
  const uploadedFileStatusMessage = getUploadedFileStatusMessage({ uploadedFileName, uploadedFileSize, uploadedFileStatus })

  /*
     * Renderizado del componente de la lista de archivos subidos batch
    */
  return (
    <Card
      title="Archivos Procesados"
      subtitle={getStringFromArray(templateExtensions)}
      timeZoneCode={timeZoneCode}
      filterData={filterData}
      hasFilter
      handleFilterSubmit={handleFilterSubmit}
    >
      <div className="batchupload__actions">
        <PermittedComponent componentId='bt-upload-files'>
          <UploadButton
            text="Cargar Archivo"
            isActive={isActiveUploadButton}
            onChange={handleChangeUploadFile}
          />
        </PermittedComponent>
        <PermittedComponent componentId='select-template-list'>
          <Dropdown
            options={templateList}
            defaultOption={dropdownDefaultOption}
            size="small"
            isCentered
            isFitContent
            isDisabled={fileBatchListIsLoading}
            isDefaultOptionHide={true}
            onChange={handleChangeSelectTemplate}
          />
        </PermittedComponent>
      </div>
      {
        uploadedFileStatus && (
          <div className="batchupload__status">
            <span
              className={classNameUploadFileStatusMessage}
            >
              {uploadedFileStatusMessage}
            </span>
            <span
              className="batchupload__status__closeicon"
              onClick={handleClickCloseUploadFileStatus}
            >
              <Icon type="close" />
            </span>
          </div>
        )
      }
      <Loader
        hasLoaderIcon
        isLoading={fileBatchListIsLoading}
      >
        <div className="batchupload__uploadfiles" onScroll={(event) => { void handleScrollUploadFiles(event) }}>
          {
            fileBatchList.map(fileBatch => {
              const isSelected = fileBatch.payout_id in selectedPayouts

              return (
                <BatchUploadFile
                  key={fileBatch.payout_id}
                  timeZoneCode={timeZoneCode}
                  fileBatch={fileBatch}
                  isSelected={isSelected}
                  onClick={handleClickUploadFile}
                />
              )
            })
          }
        </div>
      </Loader>
    </Card>
  )
}

export default BatchUpload
