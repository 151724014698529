import { type Id, toast, type ToastOptions } from 'react-toastify'
import Notification, { type NotificationProps } from '../../components/Notification/Notification'

import 'react-toastify/dist/ReactToastify.css'
import '../../components/Notification/Notification.css'

type TNotifyColor = 'info' | 'warning' | 'success' | 'error'

const createToastHandler = (color: TNotifyColor) => (
  notificationProps: NotificationProps,
  toastProps?: ToastOptions
) => toast(
  <Notification {...notificationProps} />,
  {
    containerId: 'root',
    position: 'top-center',
    hideProgressBar: true,
    className: `notification notification--${color}`,
    ...toastProps
  }
)

type TNotify = Record<TNotifyColor, (
  notificationProps: NotificationProps,
  toastProps?: ToastOptions
) => Id>

export const notify: TNotify = {
  success: (notificationProps, toastProps) => createToastHandler('success')(notificationProps, toastProps),
  error: (notificationProps, toastProps) => createToastHandler('error')(notificationProps, toastProps),
  warning: (notificationProps, toastProps) => createToastHandler('warning')(notificationProps, toastProps),
  info: (notificationProps, toastProps) => createToastHandler('info')(notificationProps, toastProps)
}
