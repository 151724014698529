import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../store/hooks'
import { login } from '../../../store/slices/auth'

import getPermissionsServices from '../../../utils/permissions/getPermissionsServices'
import getPermissionsBySubMenu from '../../../utils/permissions/getPermissionsBySubMenu'
import signInService from '../../../services/cognitoServices/signInService'
import { isEmailValid, isPasswordValid } from '../../../utils/validations/inputFieldValidations'

import { InitialInputsTouched, InitialUserLoginData } from '../../../constants/initialData'
import { type InputsTouchedType, type UserLoginDataType } from '../../../../typings/types'
import { getViewsByUserRole } from '../../../utils/permissions/getViewsByUserRole'
import { getComponentsByUserRole } from '../../../utils/permissions/getPermittedComponents'
import getSidebarMenuData from '../../../utils/permissions/getSidebarMenuData'

const MOCKED_COMPANY_INFO = [
  {
    company_id: 'APT-TEST',
    name_str: 'Apuesta Total Cypress',
    users: [
      {
        secret_key: 'sk_jJ2NaRK3QHohuvuf87SWes',
        role_id: '6',
        email_str: 'cypress@gmail.com',
        name_str: 'Demo cypress',
        route_index_str: '/payout/batch',
        user_id: '9db7859f-e6a3-465d-9995-a4d8df7e8b96'
      }
    ],
    modules: [
      {
        module_id: '57587dsf857ds8',
        name_str: 'Payout dispersión',
        gloss_str: 'AP Total',
        webhook_producer: {
          invoice: {
            url_str: 'https://l130a985rh.execute-api.us-east-1.amazonaws.com/default/webhookTesting',
            method_str: 'POST',
            payload_template_json: {},
            auth: {
              auth_type_cod: 'basic',
              username_str: 'dc6c0b0a-b7a3-4adc-acf9-ef42ff191c9f',
              password_str: 'sk_eeCM3rxKKkbXyguLPsBnkm'
            }
          }
        },
        schedule: {
          cutoff_payout: [
            {
              day_cod: 'TUESDAY',
              times_dtm: [
                '01:00'
              ]
            },
            {
              day_cod: 'WEDNESDAY',
              times_dtm: [
                '01:00'
              ]
            },
            {
              day_cod: 'THURSDAY',
              times_dtm: [
                '01:00'
              ]
            },
            {
              day_cod: 'FRIDAY',
              times_dtm: [
                '01:00'
              ]
            },
            {
              day_cod: 'SATURDAY',
              times_dtm: [
                '01:00'
              ]
            }
          ]
        },
        layers: {
          services: {
            'ob-ms-quipu': {
              service_cod: 'ob-ms-quipu',
              name_str: 'ob-ms-quipu',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/list-file-batch',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/customers',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/customer-detail',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/transaction-report',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/transaction-status',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/bank-batch',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/statement',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/statement-detail',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/invoice-detail',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/invoices',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-tukuyriku': {
              service_cod: 'ob-sl-tukuyriku',
              name_str: 'ob-sl-tukuyriku',
              endPoints: [
                {
                  endpoint_str: 'v1/user/sign-up',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-kuraka': {
              service_cod: 'ob-sl-kuraka',
              name_str: 'ob-sl-kuraka',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/{{id}}/process-status',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST',
                        'GET'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/status',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'PATCH'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'DELETE'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-mita': {
              service_cod: 'ob-sl-mita',
              name_str: 'ob-sl-mita',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/{{id}}/close',
                  roles: {
                    5: {
                      role_cod: 'Tecnología APIadmin',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/invoice-reject',
                  roles: {
                    5: {
                      role_cod: 'admin',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    6: {
                      role_cod: 'support',
                      permissions: [
                        'DELETE'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/invoice/bank-reject',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'DELETE'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'DELETE'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-ms-maqaqkuna': {
              service_cod: 'ob-ms-maqaqkuna',
              name_str: 'ob-ms-maqaqkuna',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/invoice-batch',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'POST'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'POST'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'POST'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'POST'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/{{id}}/bank-file-status',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'PATCH'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'PATCH'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-intihuatana': {
              service_code: 'ob-sl-intihuatana',
              name_str: 'ob-sl-intihuatana',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/schedule/cutoff',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/force/cutoff',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-hurin': {
              service_cod: 'ob-sl-hurin',
              name_str: 'ob-sl-hurin',
              endPoints: [
                {
                  endpoint_str: 'v1/payout/invoice/{{id}}',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET',
                        'DELETE',
                        'PATCH'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET',
                        'DELETE',
                        'PATCH'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/invoice',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/statement/available-funds',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                },
                {
                  endpoint_str: 'v1/payout/banks',
                  roles: {
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'GET'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'GET'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-chasqui': {
              service_cod: 'ob-sl-chasqui',
              name_str: 'ob-sl-chasqui',
              endPoints: [
                {
                  endpoint_str: 'v1/invoice/{{id}}/webhook-producer',
                  roles: {
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                }
              ]
            },
            'ob-sl-tambo': {
              service_cod: 'ob-sl-tambo',
              name_str: 'ob-sl-tambo',
              endPoints: [
                {
                  endpoint_str: 'v1/statement/funds',
                  roles: {
                    1: {
                      role_cod: 'admin',
                      permissions: [
                        'POST'
                      ]
                    },
                    2: {
                      role_cod: 'support',
                      permissions: [
                        'POST'
                      ]
                    },
                    3: {
                      role_cod: 'approver',
                      permissions: [
                        'POST'
                      ]
                    },
                    4: {
                      role_cod: 'reader',
                      permissions: [
                        'POST'
                      ]
                    },
                    5: {
                      role_cod: 'Tecnología API',
                      permissions: [
                        'POST'
                      ]
                    },
                    6: {
                      role_cod: 'TI OB',
                      permissions: [
                        'POST'
                      ]
                    }
                  }
                }
              ]
            }
          },
          views: {
            menu: [
              {
                title_str: 'Dispersión',
                sub_menu: [
                  {
                    title_str: 'Procesamiento por lote',
                    view: {
                      title_str: 'Procesamiento por lote',
                      url_str: '/payout/batch',
                      services: [
                        'ob-ms-quipu',
                        'ob-sl-kuraka',
                        'ob-ms-maqaqkuna'
                      ],
                      roles: {
                        1: {
                          role_cod: 'admin',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        2: {
                          role_cod: 'support',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        3: {
                          role_cod: 'approver',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        4: {
                          role_cod: 'reader',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        5: {
                          role_cod: 'Tecnología API',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        6: {
                          role_cod: 'TI OB',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        }
                      },
                      components: [
                        {
                          'export-bank-batch': {
                            roles: {
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'process-actions-item': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'bt-process-void': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'bt-process-approve': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'bt-upload-files': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'select-template-list': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'statement-menu-item': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        },
                        {
                          'alert-payout-bach': {
                            roles: {
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        }
                      ]
                    }
                  },
                  {
                    title_str: 'Estado de cuenta',
                    view: {
                      title_str: 'Estado de cuenta',
                      url_str: '/payout/statement',
                      services: [
                        'ob-ms-quipu'
                      ],
                      roles: {
                        1: {
                          role_cod: 'admin',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        2: {
                          role_cod: 'support',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        3: {
                          role_cod: 'approver',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        4: {
                          role_cod: 'reader',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        5: {
                          role_cod: 'Tecnología API',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        6: {
                          role_cod: 'TI OB',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        }
                      },
                      components: [
                        {
                          'statement-menu-item': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        }
                      ]
                    }
                  },
                  {
                    title_str: 'Clientes',
                    view: {
                      title_str: 'Clientes',
                      url_str: '/payout/customers',
                      services: [
                        'ob-ms-quipu'
                      ],
                      roles: {
                        1: {
                          role_cod: 'admin',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        2: {
                          role_cod: 'support',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        3: {
                          role_cod: 'approver',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        4: {
                          role_cod: 'reader',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        5: {
                          role_cod: 'Tecnología API',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        6: {
                          role_cod: 'TI OB',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        }
                      },
                      components: [
                        {
                          'statement-menu-item': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        }
                      ]
                    }
                  },
                  {
                    title_str: 'Pagos',
                    view: {
                      title_str: 'Pagos',
                      url_str: '/payout/invoices',
                      services: [
                        'ob-ms-quipu'
                      ],
                      roles: {
                        1: {
                          role_cod: 'admin',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        2: {
                          role_cod: 'support',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        3: {
                          role_cod: 'approver',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        4: {
                          role_cod: 'reader',
                          permissions: [
                            'EXECUTION',
                            'READ'
                          ]
                        },
                        5: {
                          role_cod: 'Tecnología API',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        },
                        6: {
                          role_cod: 'TI OB',
                          permissions: [
                            'READ',
                            'EXECUTION'
                          ]
                        }
                      },
                      components: [
                        {
                          'statement-menu-item': {
                            roles: {
                              1: {
                                role_cod: 'admin',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              2: {
                                role_cod: 'support',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              3: {
                                role_cod: 'approver',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              5: {
                                role_cod: 'Tecnología API',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              },
                              6: {
                                role_cod: 'TI OB',
                                permissions: [
                                  'READ',
                                  'EXECUTION'
                                ]
                              }
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        },
        templates: {
          batch: {
            files: [
              {
                file_ext: '.xls, xlsx',
                file_cod: 'AT'
              },
              {
                file_ext: '.xls, xlsx',
                file_cod: 'AT v2'
              }
            ]
          }
        }
      }
    ],
    time_zone_cod: 'GMT-5'
  }
  // {
  //   company_id: 'TRTO-TEST',
  //   name_str: 'Apuesta Total Cypress',
  //   users: [
  //     {
  //       secret_key: 'sk_jJ2NaRK3QHohuvuf87SWes',
  //       role_id: '6',
  //       email_str: 'cypress@gmail.com',
  //       name_str: 'Demo cypress',
  //       route_index_str: '/payout/batch',
  //       user_id: '9db7859f-e6a3-465d-9995-a4d8df7e8b96'
  //     }
  //   ],
  //   modules: [
  //     {
  //       module_id: '57587dsf857ds8',
  //       name_str: 'Payout dispersión',
  //       gloss_str: 'AP Total',
  //       webhook_producer: {
  //         invoice: {
  //           url_str: 'https://l130a985rh.execute-api.us-east-1.amazonaws.com/default/webhookTesting',
  //           method_str: 'POST',
  //           payload_template_json: {},
  //           auth: {
  //             auth_type_cod: 'basic',
  //             username_str: 'dc6c0b0a-b7a3-4adc-acf9-ef42ff191c9f',
  //             password_str: 'sk_eeCM3rxKKkbXyguLPsBnkm'
  //           }
  //         }
  //       },
  //       schedule: {
  //         cutoff_payout: [
  //           {
  //             day_cod: 'TUESDAY',
  //             times_dtm: [
  //               '01:00'
  //             ]
  //           },
  //           {
  //             day_cod: 'WEDNESDAY',
  //             times_dtm: [
  //               '01:00'
  //             ]
  //           },
  //           {
  //             day_cod: 'THURSDAY',
  //             times_dtm: [
  //               '01:00'
  //             ]
  //           },
  //           {
  //             day_cod: 'FRIDAY',
  //             times_dtm: [
  //               '01:00'
  //             ]
  //           },
  //           {
  //             day_cod: 'SATURDAY',
  //             times_dtm: [
  //               '01:00'
  //             ]
  //           }
  //         ]
  //       },
  //       layers: {
  //         services: {
  //           'ob-ms-quipu': {
  //             service_cod: 'ob-ms-quipu',
  //             name_str: 'ob-ms-quipu',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/list-file-batch',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/customers',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/customer-detail',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/transaction-report',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/transaction-status',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/bank-batch',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/statement',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/statement-detail',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/invoice-detail',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/invoices',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-tukuyriku': {
  //             service_cod: 'ob-sl-tukuyriku',
  //             name_str: 'ob-sl-tukuyriku',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/user/sign-up',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-kuraka': {
  //             service_cod: 'ob-sl-kuraka',
  //             name_str: 'ob-sl-kuraka',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/process-status',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST',
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/status',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-mita': {
  //             service_cod: 'ob-sl-mita',
  //             name_str: 'ob-sl-mita',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/close',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología APIadmin',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/invoice-reject',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/invoice/bank-reject',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'DELETE'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-ms-maqaqkuna': {
  //             service_cod: 'ob-ms-maqaqkuna',
  //             name_str: 'ob-ms-maqaqkuna',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/invoice-batch',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/{{id}}/bank-file-status',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'PATCH'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-intihuatana': {
  //             service_code: 'ob-sl-intihuatana',
  //             name_str: 'ob-sl-intihuatana',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/schedule/cutoff',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/force/cutoff',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-hurin': {
  //             service_cod: 'ob-sl-hurin',
  //             name_str: 'ob-sl-hurin',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/payout/invoice/{{id}}',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET',
  //                       'DELETE',
  //                       'PATCH'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET',
  //                       'DELETE',
  //                       'PATCH'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/invoice',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/statement/available-funds',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               },
  //               {
  //                 endpoint_str: 'v1/payout/banks',
  //                 roles: {
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'GET'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-chasqui': {
  //             service_cod: 'ob-sl-chasqui',
  //             name_str: 'ob-sl-chasqui',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/invoice/{{id}}/webhook-producer',
  //                 roles: {
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           },
  //           'ob-sl-tambo': {
  //             service_cod: 'ob-sl-tambo',
  //             name_str: 'ob-sl-tambo',
  //             endPoints: [
  //               {
  //                 endpoint_str: 'v1/statement/funds',
  //                 roles: {
  //                   1: {
  //                     role_cod: 'admin',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   2: {
  //                     role_cod: 'support',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   3: {
  //                     role_cod: 'approver',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   4: {
  //                     role_cod: 'reader',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   5: {
  //                     role_cod: 'Tecnología API',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   },
  //                   6: {
  //                     role_cod: 'TI OB',
  //                     permissions: [
  //                       'POST'
  //                     ]
  //                   }
  //                 }
  //               }
  //             ]
  //           }
  //         },
  //         views: {
  //           menu: [
  //             {
  //               title_str: 'Dispersión',
  //               sub_menu: [
  //                 {
  //                   title_str: 'Procesamiento por lote',
  //                   view: {
  //                     title_str: 'Procesamiento por lote',
  //                     url_str: '/payout/batch',
  //                     services: [
  //                       'ob-ms-quipu',
  //                       'ob-sl-kuraka',
  //                       'ob-ms-maqaqkuna'
  //                     ],
  //                     roles: {
  //                       1: {
  //                         role_cod: 'admin',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       2: {
  //                         role_cod: 'support',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       3: {
  //                         role_cod: 'approver',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       4: {
  //                         role_cod: 'reader',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       5: {
  //                         role_cod: 'Tecnología API',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       6: {
  //                         role_cod: 'TI OB',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       }
  //                     },
  //                     components: [
  //                       {
  //                         'export-bank-batch': {
  //                           roles: {
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'process-actions-item': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'bt-process-void': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'bt-process-approve': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'bt-upload-files': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'select-template-list': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'statement-menu-item': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       },
  //                       {
  //                         'alert-payout-bach': {
  //                           roles: {
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       }
  //                     ]
  //                   }
  //                 },
  //                 {
  //                   title_str: 'Estado de cuenta',
  //                   view: {
  //                     title_str: 'Estado de cuenta',
  //                     url_str: '/payout/statement',
  //                     services: [
  //                       'ob-ms-quipu'
  //                     ],
  //                     roles: {
  //                       1: {
  //                         role_cod: 'admin',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       2: {
  //                         role_cod: 'support',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       3: {
  //                         role_cod: 'approver',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       4: {
  //                         role_cod: 'reader',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       5: {
  //                         role_cod: 'Tecnología API',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       6: {
  //                         role_cod: 'TI OB',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       }
  //                     },
  //                     components: [
  //                       {
  //                         'statement-menu-item': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       }
  //                     ]
  //                   }
  //                 },
  //                 {
  //                   title_str: 'Clientes',
  //                   view: {
  //                     title_str: 'Clientes',
  //                     url_str: '/payout/customers',
  //                     services: [
  //                       'ob-ms-quipu'
  //                     ],
  //                     roles: {
  //                       1: {
  //                         role_cod: 'admin',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       2: {
  //                         role_cod: 'support',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       3: {
  //                         role_cod: 'approver',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       4: {
  //                         role_cod: 'reader',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       5: {
  //                         role_cod: 'Tecnología API',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       6: {
  //                         role_cod: 'TI OB',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       }
  //                     },
  //                     components: [
  //                       {
  //                         'statement-menu-item': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       }
  //                     ]
  //                   }
  //                 },
  //                 {
  //                   title_str: 'Pagos',
  //                   view: {
  //                     title_str: 'Pagos',
  //                     url_str: '/payout/invoices',
  //                     services: [
  //                       'ob-ms-quipu'
  //                     ],
  //                     roles: {
  //                       1: {
  //                         role_cod: 'admin',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       2: {
  //                         role_cod: 'support',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       3: {
  //                         role_cod: 'approver',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       4: {
  //                         role_cod: 'reader',
  //                         permissions: [
  //                           'EXECUTION',
  //                           'READ'
  //                         ]
  //                       },
  //                       5: {
  //                         role_cod: 'Tecnología API',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       },
  //                       6: {
  //                         role_cod: 'TI OB',
  //                         permissions: [
  //                           'READ',
  //                           'EXECUTION'
  //                         ]
  //                       }
  //                     },
  //                     components: [
  //                       {
  //                         'statement-menu-item': {
  //                           roles: {
  //                             1: {
  //                               role_cod: 'admin',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             2: {
  //                               role_cod: 'support',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             3: {
  //                               role_cod: 'approver',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             5: {
  //                               role_cod: 'Tecnología API',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             },
  //                             6: {
  //                               role_cod: 'TI OB',
  //                               permissions: [
  //                                 'READ',
  //                                 'EXECUTION'
  //                               ]
  //                             }
  //                           }
  //                         }
  //                       }
  //                     ]
  //                   }
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       },
  //       templates: {
  //         batch: {
  //           files: [
  //             {
  //               file_ext: '.xls, xlsx',
  //               file_cod: 'AT'
  //             },
  //             {
  //               file_ext: '.xls, xlsx',
  //               file_cod: 'AT v2'
  //             }
  //           ]
  //         }
  //       }
  //     }
  //   ],
  //   time_zone_cod: 'GMT-5'
  // }
]

interface MergeableCompany {
  company_id: string
  name_str: string
  time_zone_cod: string
  files: Array<{
    file_ext: string
    file_cod: string
  }>
}

interface MergeableService {
  service_cod: string
  name_str: string
  endPoints: Record<string, {
    endpoint_str: string
    roles: Record<string, {
      role_cod: string
      permissions: string[]
    }>
  }>
}

type MergedService = Omit<MergeableService, 'endPoints'> & {
  endPoints: Array<{
    endpoint_str: string
    roles: MergeableService['endPoints'][string]['roles']
  }>
}

interface MergeableMenu {
  title_str: string
  sub_menu: Record<string, {
    title_str: string
    view: {
      title_str: string
      url_str: string
      services: string[]
      roles: Record<string, {
        role_cod: string
        permissions: string[]
      }>
      components: Record<string, {
        roles: Record<string, {
          role_cod: string
          permissions: string[]
        }>
      }>
    }
  }>
}

const mergeAndGetUserDataFromCompanyInfo = (companyInfo: typeof MOCKED_COMPANY_INFO) => {
  const mergeableCompanies: MergeableCompany[] = []
  let mergeableServices: Record<string, MergeableService> = {}
  let mergeableMenus: Record<string, MergeableMenu> = {}

  companyInfo.forEach(company => {
    let mergeableFiles: Record<string, Set<string>> = {}

    company.modules.forEach(module => {
      Object.entries(module.layers.services).forEach(([serviceKey, serviceValue]) => {
        let mergeableEndPoints: Record<string, any> = {}
        if (!(serviceKey in mergeableServices)) {
          serviceValue.endPoints.forEach(endpoint => {
            mergeableEndPoints = {
              ...mergeableEndPoints,
              [endpoint.endpoint_str]: endpoint
            }
          })

          mergeableServices = {
            ...mergeableServices,
            [serviceKey]: {
              service_cod: serviceKey,
              name_str: serviceValue.name_str,
              endPoints: mergeableEndPoints
            }
          }
        } else {
          mergeableEndPoints = { ...mergeableServices[serviceKey].endPoints }
          serviceValue.endPoints.forEach(endpoint => {
            if (!(endpoint.endpoint_str in mergeableEndPoints)) {
              mergeableEndPoints = {
                ...mergeableEndPoints,
                [endpoint.endpoint_str]: endpoint
              }
            } else {
              let mergeableRoles = mergeableEndPoints[endpoint.endpoint_str].roles

              Object.entries(endpoint.roles).forEach(([roleKey, roleValue]) => {
                if (!(roleKey in mergeableRoles)) {
                  mergeableRoles = {
                    ...mergeableRoles,
                    [roleKey]: roleValue
                  }
                } else {
                  mergeableRoles = {
                    ...mergeableRoles,
                    [roleKey]: {
                      ...mergeableRoles[roleKey],
                      permissions: Array.from(new Set([
                        ...mergeableRoles[roleKey].permissions,
                        ...roleValue.permissions
                      ]))
                    }
                  }
                }
              })

              mergeableEndPoints = {
                ...mergeableEndPoints,
                [endpoint.endpoint_str]: {
                  ...mergeableEndPoints[endpoint.endpoint_str],
                  roles: mergeableRoles
                }
              }
            }
          })

          mergeableServices = {
            ...mergeableServices,
            [serviceKey]: {
              ...mergeableServices[serviceKey],
              endPoints: mergeableEndPoints
            }
          }
        }
      })

      module.layers.views.menu.forEach(menuValue => {
        let mergeableSubMenu: Record<string, any> = {}

        if (!(menuValue.title_str in mergeableMenus)) {
          menuValue.sub_menu.forEach(subMenuValue => {
            mergeableSubMenu = {
              ...mergeableSubMenu,
              [subMenuValue.title_str]: {
                ...subMenuValue,
                view: {
                  ...subMenuValue.view,
                  components: {}
                }
              }
            }

            subMenuValue.view.components.forEach(component => {
              mergeableSubMenu = {
                ...mergeableSubMenu,
                [subMenuValue.title_str]: {
                  ...mergeableSubMenu[subMenuValue.title_str],
                  view: {
                    ...mergeableSubMenu[subMenuValue.title_str].view,
                    components: {
                      ...mergeableSubMenu[subMenuValue.title_str].view.components,
                      ...component
                    }
                  }
                }
              }
            })

            mergeableMenus = {
              ...mergeableMenus,
              [menuValue.title_str]: {
                title_str: menuValue.title_str,
                sub_menu: mergeableSubMenu
              }
            }
          })
        } else {
          mergeableSubMenu = { ...mergeableMenus[menuValue.title_str].sub_menu }

          menuValue.sub_menu.forEach(subMenuValue => {
            if (!(subMenuValue.title_str in mergeableSubMenu)) {
              let mergeableComponents: Record<string, any> = {}

              subMenuValue.view.components.forEach(component => {
                const componentKey = Object.keys(component)[0] as keyof typeof component
                if (!(componentKey in mergeableComponents)) {
                  mergeableComponents = {
                    ...mergeableComponents,
                    ...component
                  }
                } else {
                  let mergeableRoles = mergeableComponents[componentKey].roles
                  const componentRoles: Record<string, any> = component[componentKey]?.roles ?? {}

                  Object.entries(componentRoles).forEach(([roleKey, roleValue]) => {
                    if (!(roleKey in mergeableRoles)) {
                      mergeableRoles = {
                        ...mergeableRoles,
                        [roleKey]: roleValue
                      }
                    } else {
                      mergeableRoles = {
                        ...mergeableRoles,
                        [roleKey]: {
                          ...mergeableRoles[roleKey],
                          permissions: Array.from(new Set([
                            ...mergeableRoles[roleKey].permissions,
                            ...roleValue.permissions
                          ]))
                        }
                      }
                    }
                  })

                  mergeableComponents = {
                    ...mergeableComponents,
                    [componentKey]: {
                      ...mergeableComponents[componentKey],
                      roles: mergeableRoles
                    }
                  }
                }
              })

              mergeableSubMenu = {
                ...mergeableSubMenu,
                [subMenuValue.title_str]: {
                  ...subMenuValue,
                  view: {
                    ...subMenuValue.view,
                    components: mergeableComponents
                  }
                }
              }
            } else {
              const mergeableServices = new Set(mergeableSubMenu[subMenuValue.title_str].view.services)
              let mergeableRoles = mergeableSubMenu[subMenuValue.title_str].view.roles
              let mergeableComponents: Record<string, any> = mergeableSubMenu[subMenuValue.title_str].view.components

              subMenuValue.view.services.forEach(service => {
                mergeableServices.add(service)
              })

              Object.entries(subMenuValue.view.roles).forEach(([roleKey, roleValue]) => {
                if (!(roleKey in mergeableRoles)) {
                  mergeableRoles = {
                    ...mergeableRoles,
                    [roleKey]: roleValue
                  }
                } else {
                  mergeableRoles = {
                    ...mergeableRoles,
                    [roleKey]: {
                      ...mergeableRoles[roleKey],
                      permissions: Array.from(new Set([
                        ...mergeableRoles[roleKey].permissions,
                        ...roleValue.permissions
                      ]))
                    }
                  }
                }
              })

              subMenuValue.view.components.forEach(component => {
                const componentKey = Object.keys(component)[0] as keyof typeof component
                if (!(componentKey in mergeableComponents)) {
                  mergeableComponents = {
                    ...mergeableComponents,
                    ...component
                  }
                } else {
                  let mergeableRoles = mergeableComponents[componentKey].roles
                  const componentRoles: Record<string, any> = component[componentKey]?.roles ?? {}

                  Object.entries(componentRoles).forEach(([roleKey, roleValue]) => {
                    if (!(roleKey in mergeableRoles)) {
                      mergeableRoles = {
                        ...mergeableRoles,
                        [roleKey]: roleValue
                      }
                    } else {
                      mergeableRoles = {
                        ...mergeableRoles,
                        [roleKey]: {
                          ...mergeableRoles[roleKey],
                          permissions: Array.from(new Set([
                            ...mergeableRoles[roleKey].permissions,
                            ...roleValue.permissions
                          ]))
                        }
                      }
                    }
                  })

                  mergeableComponents = {
                    ...mergeableComponents,
                    [componentKey]: {
                      ...mergeableComponents[componentKey],
                      roles: mergeableRoles
                    }
                  }
                }
              })

              mergeableSubMenu[subMenuValue.title_str].view.services = Array.from(mergeableServices)
              mergeableSubMenu[subMenuValue.title_str].view.roles = mergeableRoles
              mergeableSubMenu[subMenuValue.title_str].view.components = mergeableComponents
            }
          })
        }
      })

      module.templates.batch.files.forEach(file => {
        if (!(file.file_cod in mergeableFiles)) {
          mergeableFiles = {
            ...mergeableFiles,
            [file.file_cod]: new Set([file.file_ext])
          }
        } else {
          mergeableFiles = {
            ...mergeableFiles,
            [file.file_cod]: mergeableFiles[file.file_cod].add(file.file_ext)
          }
        }
      })
    })

    const { company_id, name_str, time_zone_cod } = company
    mergeableCompanies.push({
      company_id,
      name_str,
      time_zone_cod,
      files: Object.keys(mergeableFiles).map(file_cod => ({
        file_cod,
        file_ext: Array.from(mergeableFiles[file_cod]).join(', ')
      }))
    })
  })

  const mergedServices: Record<string, MergedService> = {}
  for (const serviceKey in mergeableServices) {
    const { endPoints } = mergeableServices[serviceKey]
    mergedServices[serviceKey] = {
      ...mergeableServices[serviceKey],
      endPoints: Object.values(endPoints)
    }
  }

  const mergedMenus = Object.values(mergeableMenus).map(menu => {
    return {
      ...menu,
      sub_menu: Object.values(menu.sub_menu).map(subMenu => {
        return {
          ...subMenu,
          view: {
            ...subMenu.view,
            components: Object.keys(subMenu.view.components).map(componentKey => ({
              [componentKey]: subMenu.view.components[componentKey]
            }))
          }
        }
      })
    }
  })

  return {
    company: mergeableCompanies,
    services: mergedServices,
    menu: mergedMenus
  }
}

const useLogin = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [userLoginData, setUserLoginData] = useState<UserLoginDataType>(InitialUserLoginData)
  const [inputsTouched, setInputsTouched] = useState<InputsTouchedType>(InitialInputsTouched)
  const [hasLoginEmailError, setHasLoginEmailError] = useState<boolean>(false)
  const [hasLoginPasswordError, setHasLoginPasswordError] = useState<boolean>(false)
  const [submitLoginErrorMessage, setSubmitLoginErrorMessage] = useState<string>('')
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState<boolean>(false)

  const navigate = useNavigate()
  const distpatch = useAppDispatch()

  /*
     * Función que maneja la data ingresada en los input fields de la página de 'Login'
     * y la guarda en un objeto de estado al momento
    */
  const handleLoginInputValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target

    setUserLoginData({ ...userLoginData, [name]: value })
    setSubmitLoginErrorMessage('')
  }

  /*
     * Función que marca el campo como "tocado" cuando el usuario interactúa con los inputs
    */
  const handleLoginInputBlurs = (event: React.FocusEvent<HTMLInputElement>) => {
    event?.preventDefault()

    const { name, value } = event?.target

    if (value?.length !== 0) {
      setInputsTouched({ ...inputsTouched, [name]: true })
    };
  }

  /*
     * Función que maneja las acciones que se disparan al hacer click al botón submit del Login
    */
  const handleSubmitSignIn = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault()

    const { username, password } = userLoginData

    try {
      setIsLoadingLogin(true)
      const cognitoData = await signInService(username, password)
      // company_info will be an array of objects
      const companyInfo = JSON.parse(cognitoData?.idToken?.payload?.company_info.replaceAll("'", '"'))
      const userData = companyInfo[0]
      const { company, services, menu } = mergeAndGetUserDataFromCompanyInfo(companyInfo)

      distpatch(login({
        user: {
          user_id: userData?.users?.at(0)?.user_id,
          name_str: userData?.users?.at(0)?.name_str,
          email_str: userData?.users?.at(0)?.email_str,
          role_id: userData?.users?.at(0)?.role_id,
          route_index_str: userData?.users?.at(0)?.route_index_str,
          secret_key: userData?.users?.at(0)?.secret_key
        },
        company,
        permissions: getViewsByUserRole({ menu }, userData.users.at(0)?.role_id ?? ''),
        services: getPermissionsServices(services, userData?.users?.at(0)?.role_id ?? ''),
        servicesBySubMenu: getPermissionsBySubMenu(menu),
        components: getComponentsByUserRole({ menu }, userData?.users?.at(0)?.role_id ?? ''),
        sidebarMenuData: getSidebarMenuData(menu)
      }))

      navigate(process.env.REACT_APP_COGNITO_LOGIN_SUCCESS_URL ?? '')
    } catch (error: any) {
      setSubmitLoginErrorMessage(error?.message || JSON.stringify(error))
      setIsLoadingLogin(false)
    } finally {
      setIsLoadingLogin(false)
    }
  }

  /*
     * Efecto que setea el valor del error cuando la data del usuario en el Login cambia
    */
  useEffect(() => {
    if (!userLoginData) return

    const { username, password } = userLoginData
    const { username: usernameBlur, password: passwordBlur } = inputsTouched

    const emailValidation = isEmailValid(username)
    const passwordValidation = isPasswordValid(password)

    setHasLoginEmailError(!emailValidation && usernameBlur)
    setHasLoginPasswordError(!passwordValidation && passwordBlur)

    setIsActiveSubmitButton(emailValidation && passwordValidation)
  }, [userLoginData, inputsTouched])

  return {
    // States
    hasLoginEmailError,
    hasLoginPasswordError,
    submitLoginErrorMessage,
    isActiveSubmitButton,
    isLoadingLogin,

    // Function States

    // Functions
    handleLoginInputValues,
    handleLoginInputBlurs,
    handleSubmitSignIn
  }
}

export default useLogin
