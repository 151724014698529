import { useEffect, useRef, useState } from 'react'
import { type Id, toast } from 'react-toastify'

import BatchUpload from './BatchUpload/BatchUpload'
import BatchDispersionState from './BatchDispersionState/BatchDispersionState'

import getFormattedFileUploadDate from '../../../../utils/date/getFormattedFileUploadDate'

import 'react-toastify/dist/ReactToastify.css'
import { notify } from '../../../../utils/commons'
import type { TUploadedFileStatus, PayoutDataType, TBatchPayoutData, DropdownOptionType } from '../../../../../typings/types'

interface IBatchViewProps {
    canViewToast: boolean
    toastClassName: string
    loadingToastList: boolean
    batchToastList: PayoutDataType[] | null
    timeZoneCode: string
    uploadedFile?: File
    templateExtensions: string[]
    templateList: any
    fileBatchList: TBatchPayoutData[]
    uploadedFileStatus?: TUploadedFileStatus
    // uploadFileIdSelected: string
    reloadBatchDispersionState: () => void
    // newPayoutId: string
    fileBatchListIsLoading: boolean
    isActiveUploadButton: boolean
    // currentPayout: PayoutDataType
    filterData: any
    selectedPayouts: Record<string, PayoutDataType>
    fetchedPayouts: Record<string, PayoutDataType>
    isFetchingPayout: boolean
    // setCurrentPayout: any
    // setIsDispersionStateLoading: any
    setSelectedPayouts: React.Dispatch<React.SetStateAction<Record<string, PayoutDataType>>>
    setIsFetchingPayout: React.Dispatch<React.SetStateAction<boolean>>
    setAreLoadingPayoutsList: React.Dispatch<React.SetStateAction<boolean>>
    handleChangeUploadFile: React.ChangeEventHandler<HTMLInputElement> | undefined
    handleChangeSelectTemplate: (option: DropdownOptionType) => void
    handleClickCloseUploadFileStatus: React.MouseEventHandler<HTMLSpanElement> | undefined
    handleClickUploadFile: (payout: PayoutDataType) => void
    handleScrollUploadFiles: (event: React.UIEvent<HTMLDivElement, UIEvent>) => Promise<void>
    // handleDispersionLoadingDone: (option: boolean) => void
    handleFilterSubmit: any
};

const BatchView = ({
  toastClassName,
  canViewToast,
  loadingToastList,
  batchToastList,
  timeZoneCode,
  uploadedFile,
  templateExtensions,
  templateList,
  fileBatchList,
  uploadedFileStatus,
  fetchedPayouts,
  isFetchingPayout,
  // uploadFileIdSelected,
  selectedPayouts,
  setIsFetchingPayout,
  setAreLoadingPayoutsList,
  reloadBatchDispersionState,
  // newPayoutId,
  fileBatchListIsLoading,
  isActiveUploadButton,
  // currentPayout,
  filterData,
  // setCurrentPayout,
  // setIsDispersionStateLoading,
  handleChangeUploadFile,
  handleChangeSelectTemplate,
  handleClickCloseUploadFileStatus,
  handleClickUploadFile,
  handleScrollUploadFiles,
  // handleDispersionLoadingDone,
  handleFilterSubmit
}: IBatchViewProps) => {
  const prevBatchToastListRef = useRef<any>()
  const [toastIds, setToastIds] = useState<Id[]>([])

  const notifyCurrentBatch = (currentBatch: PayoutDataType) => {
    const { status_cod } = currentBatch
    const formattedFileUploadDate = getFormattedFileUploadDate(currentBatch, timeZoneCode)
    const isProcessing = ['PROCESSING', 'CHECKING'].includes(status_cod)
    const title = `${formattedFileUploadDate} Dispersión`
    const description = isProcessing ? 'Proceso de payout cargado' : 'Proceso payout en espera de aprobación'
    const notifyColor = status_cod === 'WAITING' ? 'warning' : 'info'
    const toastId = notify[notifyColor]({
      title,
      description
    }, {
      autoClose: 1200000,
      closeButton: toastClassName !== 'cant-execute-toast'
    })
    setToastIds(prevToastIds => [...prevToastIds, toastId])
  }

  useEffect(() => {
    if (!loadingToastList && batchToastList && canViewToast && toastClassName !== 'cant-view-toast') {
      if (!toastIds.some((toastId: any) => toast.isActive(toastId))) {
        batchToastList.forEach(currentBatch => {
          const browserTitle = `${getFormattedFileUploadDate(currentBatch, timeZoneCode)} Dispersión`
          const browserDescription = ['PROCESSING', 'CHECKING'].includes(currentBatch?.status_cod)
            ? 'Proceso de payout cargado'
            : 'Proceso payout en espera de aprobación'
          notifyCurrentBatch(currentBatch)
          showNotification(browserTitle, browserDescription)
        })
      } else if (JSON.stringify(prevBatchToastListRef.current) !== JSON.stringify(batchToastList)) {
        batchToastList.forEach((currentBatch: any) => {
          const browserTitle = `${getFormattedFileUploadDate(currentBatch, timeZoneCode)} Dispersión`
          const browserDescription = ['PROCESSING', 'CHECKING'].includes(currentBatch?.status_cod)
            ? 'Proceso de payout cargado'
            : 'Proceso payout en espera de aprobación'
          notifyCurrentBatch(currentBatch)
          showNotification(browserTitle, browserDescription)
        })
      }
    }
    prevBatchToastListRef.current = batchToastList
  }, [loadingToastList, batchToastList])

  const showNotification = (title: string, description: string) => {
    if (Notification.permission === 'granted') {
      // eslint-disable-next-line no-new
      new Notification(title, {
        body: description
      })
    } else {
      console.log('Los permisos de notificación no han sido otorgados.')
    }
  }

  return (
    <div className="view batch">
      <div className="view__header">
        <h1>Batch Payout</h1>
        <h2>Home - Payouts</h2>
      </div>
      <div className="view__body">
        <BatchUpload
          timeZoneCode={timeZoneCode}
          uploadedFile={uploadedFile}
          templateExtensions={templateExtensions}
          templateList={templateList}
          fileBatchList={fileBatchList}
          fileBatchListIsLoading={fileBatchListIsLoading}
          isActiveUploadButton={isActiveUploadButton}
          uploadedFileStatus={uploadedFileStatus}
          // fetchedPayouts={fetchedPayouts}
          // uploadFileIdSelected={uploadFileIdSelected}
          // currentPayout={currentPayout}
          filterData={filterData}
          selectedPayouts={selectedPayouts}
          handleChangeUploadFile={handleChangeUploadFile}
          handleChangeSelectTemplate={handleChangeSelectTemplate}
          handleClickCloseUploadFileStatus={handleClickCloseUploadFileStatus}
          handleClickUploadFile={handleClickUploadFile}
          handleScrollUploadFiles={handleScrollUploadFiles}
          handleFilterSubmit={handleFilterSubmit}
        />
        <BatchDispersionState
          timeZoneCode={timeZoneCode}
          // uploadFileIdSelected={uploadFileIdSelected}
          selectedPayouts={selectedPayouts}
          fetchedPayouts={fetchedPayouts}
          isFetchingPayout={isFetchingPayout}
          reloadBatchDispersionState={reloadBatchDispersionState}
          setIsFetchingPayout={setIsFetchingPayout}
          setAreLoadingPayoutsList={setAreLoadingPayoutsList}
          // newPayoutId={newPayoutId}
          // currentPayout={currentPayout}
          // setCurrentPayout={setCurrentPayout}
          // setIsDispersionStateLoading={setIsDispersionStateLoading}
          // handleDispersionLoadingDone={handleDispersionLoadingDone}
        />
      </div>
    </div>
  )
}

export default BatchView
