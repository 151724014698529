import ModalContext from '../../../contexts/ModalContext'

import Sidebar from '../../../layouts/Sidebar/Sidebar'
import Header from '../../../layouts/Header/Header'
import Main from '../../../layouts/Main/Main'
import Footer from '../../../layouts/Footer/Footer'
import Modal from '../../../components/Modal/Modal'
import { Helmet } from 'react-helmet'

interface IHomeViewProps {
    modalData: any
    setModalData: any
    sessionUserIndexRoute: any
    openHamburgerMenu: boolean
    isMainLoading: boolean
    handleSidebarMenuGroupItemClick: any
    handleClickHamburguerMenu: React.MouseEventHandler<HTMLDivElement> | undefined
};

const HomeView = ({
  modalData,
  setModalData,
  sessionUserIndexRoute,
  openHamburgerMenu,
  isMainLoading,
  handleSidebarMenuGroupItemClick,
  handleClickHamburguerMenu
}: IHomeViewProps) => {
  return (
    <div className="dashboard">
      <Helmet>
        <title>OpenBanking</title>
      </Helmet>
      <div className="dashboard_container">
        <ModalContext.Provider value={{ modalData, setModalData }}>
          <Modal data={modalData} />
          <Sidebar
            sessionUserIndexRoute={sessionUserIndexRoute}
            openHamburgerMenu={openHamburgerMenu}
            canOnMouseEnterSidebar={false}
            handleSidebarMenuGroupItemClick={handleSidebarMenuGroupItemClick}
          />
          <div className="dashboard__content">
            <Header
              handleClickHamburguerMenu={handleClickHamburguerMenu}
            />
            <Main />
            <Footer />
          </div>
        </ModalContext.Provider>
      </div>
    </div>
  )
}

export default HomeView
