import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { useLocation } from 'react-router-dom'

import ModalContext from '../../../../contexts/ModalContext'

import listFileBatchService from '../../../../services/payoutServices/listFileBatchService'

import { getPermittedComponents } from '../../../../utils/permissions/getPermittedComponents'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import { useBasePath } from '../../../../hooks'

interface Props {
  fileBatchList: any
}

const useBatchToast = ({ fileBatchList }: Props) => {
  const basePath = useBasePath()
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const companyData = useAppSelector(state => state.authReducer.company)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)
  const componentsRedux = useAppSelector((state) => state.authReducer.components)

  const { pathname } = useLocation()
  const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const { setModalData } = useContext(ModalContext)

  const [loadingToastList, setLoadingToastList] = useState(true)
  const [batchToastList, setBatchToastList] = useState(null)

  const [canViewToast, setCanViewToast] = useState(false)
  const [toastClassName, setToastClassName] = useState('')

  const permittedComponentsByView = getPermittedComponents(componentsRedux?.allComponents, componentsRedux?.payoutCustomersComponents, pathname, reduxUser?.role_id ?? '')
  const component = permittedComponentsByView?.find((permittedComponent: any) => permittedComponent?.component_str === 'alert-payout-bach')
  const componentPermissions = component?.permissions

  useEffect(() => {
    if (!componentPermissions?.filter((permission: any) => permission?.trim() !== '')?.length) {
      setCanViewToast(false)
    } else {
      setCanViewToast(true)

      if (!componentPermissions?.includes('READ')) {
        setToastClassName('cant-view-toast')
      };

      if (!componentPermissions?.includes('EXECUTION')) {
        setToastClassName('cant-execute-toast')
      };
    };
  }, [permittedComponentsByView])

  const getToastListFileBatch = async () => {
    setLoadingToastList(true)
    if (validPermittedServices('listFileBatch', permissions)) {
      try {
        const response = await listFileBatchService({
          company_id: companyData?.length === 1 ? companyData[0].company_id : undefined,
          limit_int: 1,
          offset_int: 0,
          orderby: 'batch.file.request_dtm',
          orderdir_str: 'desc',
          filterData: { processStatus: 'PROCESSING,CHECKING,WAITING', nameId: '', calendar: { iniDte: '', finDte: '' } },
          user: reduxUser
        })
        const listData = response?.data?.['list-file-batch']
        setBatchToastList(listData)
        setLoadingToastList(false)
      } catch (error) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
        setLoadingToastList(false)
      } finally {
        setLoadingToastList(false)
      }
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'listFileBatch' })
    }
  }

  useEffect(() => {
    if (canViewToast && toastClassName !== 'cant-view-toast') {
      getToastListFileBatch() // eslint-disable-line
      const intervalId = setInterval(getToastListFileBatch, 300000); // eslint-disable-line
      return () => clearInterval(intervalId); // eslint-disable-line
    }
  }, [canViewToast, toastClassName])

  return {
    // States
    loadingToastList,
    batchToastList,
    canViewToast,
    toastClassName,

    // Functions
    getToastListFileBatch
  }
}

export default useBatchToast
