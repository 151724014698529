const banksData = [
  {
    id: 'all',
    name: 'Todos',
    typeLogo: 'banklogo',
    widthLogo: 30,
    heightLogo: 30,
    alt: 'All Banks Logo'
  },
  {
    id: 'bcp',
    name: 'BCP',
    typeLogo: 'bcplogo',
    widthLogo: 64,
    heightLogo: 16,
    alt: 'BCP Bank Logo'
  },
  {
    id: 'bbva',
    name: 'BBVA',
    typeLogo: 'bbvalogo',
    widthLogo: 58,
    heightLogo: 18,
    alt: 'BBVA Bank Logo'
  },
  {
    id: 'ibk',
    name: 'IBK',
    typeLogo: 'ibklogo',
    widthLogo: 30,
    heightLogo: 30,
    alt: 'Interbank Bank Logo'
  },
  {
    id: 'sco',
    name: 'SCOT',
    typeLogo: 'scologo',
    widthLogo: 30,
    heightLogo: 30,
    alt: 'Scotiabank Bank Logo'
  },
  {
    id: 'cci',
    name: 'OTROS',
    typeLogo: 'banklogo',
    widthLogo: 30,
    heightLogo: 30,
    alt: 'Others Banks Logo'
  }
] as const

export default banksData
