import Badge from '../../../../../../components/Badge/Badge'
import Icon from '../../../../../../components/Icon/Icon'
import Timeline from '../../../../../../components/Timeline/Timeline'
import Timer from '../../../../../../components/Timer/Timer'
import RadarChart from '../../../../../../components/Charts/RadarChart'

import processStatusData from '../../../../../../constants/processStatusData'

import type { PayoutDataEventType, PayoutDataType, TBankCode, TBankSelected } from '../../../../../../../typings/types'

import getCapitalizedString from '../../../../../../utils/commons/getCapitalizedString'
import getBatchDispersionStateDetailTimeLineData from '../../../../../../utils/data/getBatchDispersionStateDetailTimeLineData'
import getBatchDispersionStateDetailRadarChartData from '../../../../../../utils/data/getBatchDispersionStateDetailRadarChartData'

import './BatchDispersionStateDetail.css'
import { getPayoutCreatedDtm } from '../../../../../../utils/data/getPayoutCreatedDtm'

const mergePayouts = (payouts: Record<string, PayoutDataType>, bankCode: TBankCode = 'all') => {
  const payoutsIds = Object.keys(payouts)
  const payoutsSize = payoutsIds.length

  if (payoutsSize === 1) {
    const payoutId = payoutsIds[0]
    const payout = payouts[payoutId]
    const { events, total_rows_int, status_cod } = payout

    return {
      events,
      totalRowsInt: total_rows_int,
      statusCode: status_cod,
      initialRequestDtm: getPayoutCreatedDtm(payout)
    }
  }

  if (payoutsSize === 0) {
    return {
      events: {},
      totalRowsInt: 0,
      statusCode: '',
      initialRequestDtm: ''
    }
  }

  let mergeableEvents = {} as any
  let sumTotalRowsInt = 0
  let statusCode = ''
  let initialRequestDtm = ''

  for (const payoutId in payouts) {
    const payout = payouts[payoutId]
    const { total_rows_int, status_cod, origin_cod } = payout
    if (!origin_cod) continue

    sumTotalRowsInt += total_rows_int
    statusCode = status_cod

    if (initialRequestDtm === '') {
      initialRequestDtm = getPayoutCreatedDtm(payout)
    } else {
      const currentRequestDtm = getPayoutCreatedDtm(payout)
      if (currentRequestDtm < initialRequestDtm) {
        initialRequestDtm = currentRequestDtm
      }
    }

    if (!payout.events) continue
    if (origin_cod === 'API') {
      const eventsByBankCode = payout.events
      const events = eventsByBankCode[bankCode]
      if (!eventsByBankCode[bankCode]) continue
      for (const key in events) {
        const eventKey = key as keyof typeof events
        const event = events[eventKey]

        if (!mergeableEvents) {
          mergeableEvents = {
            [bankCode]: {
              [eventKey]: event
            }
          }

          continue
        }

        if (!mergeableEvents[bankCode]) {
          mergeableEvents = {
            ...mergeableEvents,
            [bankCode]: {
              [eventKey]: event
            }
          }

          continue
        }

        if (!mergeableEvents[bankCode][eventKey]) {
          mergeableEvents = {
            ...mergeableEvents,
            [bankCode]: {
              ...mergeableEvents[bankCode],
              [eventKey]: event
            }
          }

          continue
        }

        const mergeableEvent: PayoutDataEventType | undefined = mergeableEvents[bankCode][eventKey]
        let requestDtm = event.request_dtm

        if (mergeableEvent?.request_dtm !== undefined && event?.request_dtm !== undefined) {
          const isOlderRequestDtm: boolean = mergeableEvent.request_dtm < event.request_dtm
          requestDtm = isOlderRequestDtm ? event.request_dtm : mergeableEvent.request_dtm
        }

        mergeableEvents = {
          ...mergeableEvents,
          [bankCode]: {
            ...mergeableEvents[bankCode],
            [eventKey]: {
              ...mergeableEvents[bankCode][eventKey],
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              total_dcm: (mergeableEvents[bankCode][eventKey]?.total_dcm ?? 0) + (event?.total_dcm ?? 0),
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              total_rows_int: (mergeableEvents[bankCode][eventKey]?.total_rows_int ?? 0) + (event?.total_rows_int ?? 0),
              request_dtm: requestDtm
            }
          }
        }
      }

      continue
    }

    if (origin_cod === 'BATCH') {
      const events = payout.events
      for (const key in events) {
        const eventKey = key as keyof typeof events
        const event = events[eventKey]

        if (!mergeableEvents) {
          mergeableEvents = {
            [eventKey]: event
          }

          continue
        }

        if (!mergeableEvents[eventKey]) {
          mergeableEvents = {
            ...mergeableEvents,
            [eventKey]: event
          }

          continue
        }

        const mergeableEvent: PayoutDataEventType | undefined = mergeableEvents[eventKey]
        let requestDtm = event.request_dtm

        if (mergeableEvent?.request_dtm !== undefined && event?.request_dtm !== undefined) {
          const isOlderRequestDtm: boolean = mergeableEvent.request_dtm < event.request_dtm
          requestDtm = isOlderRequestDtm ? event.request_dtm : mergeableEvent.request_dtm
        }

        mergeableEvents = {
          ...mergeableEvents,
          [eventKey]: {
            ...mergeableEvents[eventKey],
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            total_dcm: (mergeableEvents[eventKey]?.total_dcm ?? 0) + (event?.total_dcm ?? 0),
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            total_rows_int: (mergeableEvents[eventKey]?.total_rows_int ?? 0) + (event?.total_rows_int ?? 0),
            request_dtm: requestDtm
          }
        }
      }
    }
  }

  return {
    events: mergeableEvents,
    totalRowsInt: sumTotalRowsInt,
    statusCode,
    initialRequestDtm
  }
}

interface IBatchDispersionStateDetailProps {
    timeZoneCode: string
    fetchedPayouts: Record<string, PayoutDataType>
    payoutsOriginCode: PayoutDataType['origin_cod']
    payoutsStatusCode?: PayoutDataType['status_cod']
    bankSelected: TBankSelected
};

const BatchDispersionStateDetail = ({ timeZoneCode, fetchedPayouts, bankSelected, payoutsOriginCode, payoutsStatusCode }: IBatchDispersionStateDetailProps) => {
  const isEmptyFetchPayouts = Object.keys(fetchedPayouts).length === 0
  if (isEmptyFetchPayouts) return null
  if (!payoutsOriginCode) return null
  if (!payoutsStatusCode) return null

  const { events, totalRowsInt, initialRequestDtm } = mergePayouts(fetchedPayouts, bankSelected)
  const currentProcessStatus = processStatusData.find(process => process.name.toUpperCase() === payoutsStatusCode)
  const currentProcessStatusName = getCapitalizedString(currentProcessStatus?.title ?? payoutsStatusCode)
  const currentProcessStatusColor = currentProcessStatus?.color
  const isFromApi = payoutsOriginCode === 'API'
  const isDone = ['SUCCESS', 'VOIDED'].includes(payoutsStatusCode)
  const timelineData = getBatchDispersionStateDetailTimeLineData(isFromApi ? events[bankSelected] : events)
  const radarChartData = getBatchDispersionStateDetailRadarChartData(isFromApi ? events[bankSelected] : events, totalRowsInt)
  let successRequestDtm: string | undefined

  if (events) {
    if (isFromApi) {
      successRequestDtm = payoutsStatusCode === 'VOIDED' ? events[bankSelected]?.voided?.request_dtm : events[bankSelected]?.paid?.request_dtm
    } else {
      successRequestDtm = payoutsStatusCode === 'VOIDED' ? events.voided?.request_dtm : events.paid?.request_dtm
    }
  }

  /*
     * Renderizado del componente del detalle del Estado de Dispersión
    */
  return (
    <div className="batchdispersionstatedetail">
      <div className="batchdispersionstatedetail__payorders">
        <div className="batchdispersionstatedetail__payorders__icon">
          <Icon type="moneybag"/>
        </div>
        <div className="batchdispersionstatedetail__payorders__text">
          <span className="batchdispersionstatedetail__payorders__time">
            <Timer
              timeZoneCode={timeZoneCode}
              initialDate={initialRequestDtm}
              finalDate={successRequestDtm}
              isDone={isDone}
            />
          </span>
          <h4 className="batchdispersionstatedetail__payorders__title">Ordenes de pago</h4>
        </div>
      </div>
      <div className="batchdispersionstatedetail__process">
        <div className="batchdispersionstatedetail__process__timeline">
          <Badge text={currentProcessStatusName} color={currentProcessStatusColor} />
          <Timeline data={timelineData} />
        </div>
        <div className="batchdispersionstatedetail__process__chart">
          <RadarChart data={radarChartData} />
        </div>
      </div>
    </div>
  )
}

export default BatchDispersionStateDetail
