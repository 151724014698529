import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import Filter from '../../../../components/Filter/Filter'
import Table from '../../../../components/Table/Table'

interface IStatementViewProps {
    filterData: any
    handleFilterSubmit: any
    data: any
    tableRef: any
    timeZoneCode: string
    isLoadingStatementTable: boolean
    setIsOpenCalendar: any
    handleStatementExportButton: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleScrollStatementTableData: React.UIEventHandler<HTMLTableSectionElement> | undefined
};

const StatementView = ({
  filterData,
  handleFilterSubmit,
  data,
  tableRef,
  timeZoneCode,
  isLoadingStatementTable,
  setIsOpenCalendar,
  handleStatementExportButton,
  handleScrollStatementTableData
}: IStatementViewProps) => {
  return (
    <div className="view statementView">
      <div className="view__header">
        <h1>Statement</h1>
        <h2>Home - Statement</h2>
      </div>
      <div className="view__body">
        <Card
          title="Balance"
          subtitle="Pagos de dispersion"
          headerChildren={
            <>
              <Filter
                filterData={filterData}
                dropdownType="invoice"
                type="secondary"
                size="large"
                canOnlySelectSameMonth={true}
                handleFilterSubmit={handleFilterSubmit}
              />
              <Button
                text="Exportar"
                type="secondary"
                size="large"
                isFitContent
                isDisabled={isLoadingStatementTable}
                onClick={handleStatementExportButton}
              />
            </>
          }
          timeZoneCode={timeZoneCode}
          // eslint-disable-next-line
          children={
            <Table
              data={data}
              tableRef={tableRef}
              isLoadingTableData={isLoadingStatementTable}
              handleScrollTableData={handleScrollStatementTableData}
            />
          }
        />
      </div>
    </div>
  )
}

export default StatementView
