/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Card from '../../../../../components/Card/Card'
import Image from '../../../../../components/Image/Image'
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar'
import Table from '../../../../../components/Table/Table'
import Toggle from '../../../../../components/Toggle/Toggle'

interface ICustomerDetailViewProps {
    timeZoneCode: string
    customerData: any
    customerInvoicesTableData: any
    isLoading: boolean
    handleScrollCustomerInvoicesTableData: any
    handleCustomerInvoicesTableDataClick: any
};

const CustomerDetailView = ({
  timeZoneCode,
  customerData,
  customerInvoicesTableData,
  isLoading,
  handleScrollCustomerInvoicesTableData,
  handleCustomerInvoicesTableDataClick
}: ICustomerDetailViewProps) => {
  return (
    <div className="view customerDetail">
      <div className="view__header">
        <h1>Cliente detalle</h1>
        <h2>Home - Dispersión - Cliente</h2>
      </div>
      <div className="view__body">
        <div className="customerDetail__body__left">
          <Card
            timeZoneCode={timeZoneCode}
            // eslint-disable-next-line
            children={
              !isLoading && (
                <div className="customerDetail__profile">
                  <div className="customerDetail__profile__header">
                    <Image
                      type="profiledefault"
                      width={150}
                      height={150}
                      alt="User profile photo"
                      isCircular
                    />
                    <span className="customerDetail__profile__name">{customerData?.name_str}</span>
                    <span className="customerDetail__profile__email">{customerData?.email_str}</span>
                    <ProgressBar
                      title={customerData?.charts?.[0]?.title_str}
                      type={customerData?.charts?.[0]?.type_cod}
                      currentValue={customerData?.charts?.[0]?.current_int}
                      maxValue={customerData?.charts?.[0]?.max_int}
                    />
                  </div>
                  <div className="customerDetail__profile__body">
                    <div className="customerDetail__profile__data">
                      <h4 className="customerDetail__profile__data__label">Id OB</h4>
                      <span className="customerDetail__profile__data__content">{customerData?.customer_id}</span>
                    </div>
                    <div className="customerDetail__profile__data">
                      <h4 className="customerDetail__profile__data__label">Id Cliente</h4>
                      <span className="customerDetail__profile__data__content">{customerData?.external_customer_id}</span>
                    </div>
                    <div className="customerDetail__profile__data">
                      <h4 className="customerDetail__profile__data__label">Tipo de documento</h4>
                      <span className="customerDetail__profile__data__content">{customerData?.document_type}</span>
                    </div>
                    <div className="customerDetail__profile__data">
                      <h4 className="customerDetail__profile__data__label">Número de documento</h4>
                      <span className="customerDetail__profile__data__content">{customerData?.document_id}</span>
                    </div>
                    <div className="customerDetail__profile__data">
                      <h4 className="customerDetail__profile__data__label">Teléfono</h4>
                      <span className="customerDetail__profile__data__content">{customerData?.phone_cod}</span>
                    </div>
                  </div>
                </div>
              )
            }
          />
        </div>
        <div className="customerDetail__body__right">
          <Card
            timeZoneCode={timeZoneCode}
            title="Historial de movimientos"
            // eslint-disable-next-line
            children={
              <Table
                data={customerInvoicesTableData}
                isLoadingTableData={isLoading}
                handleScrollTableData={handleScrollCustomerInvoicesTableData}
                handleTableDataClick={handleCustomerInvoicesTableDataClick}
              />
            }
          />
          <Card
            timeZoneCode={timeZoneCode}
            title="Cuentas bancarias"
            // eslint-disable-next-line
            children={
              <ul className="customerDetail__bankAccounts">
                {
                  customerData?.bank_accounts?.map((bank: any) => {
                    let badgeText
                    let badgeColor: 'yellow' | 'blue' | 'red' | 'green' | 'gray'

                    if ((Math.abs(bank?.stats?.total_success_int) > Math.abs(bank?.stats?.total_error_int)) ||
                                        (Math.abs(bank?.stats?.total_success_int) === Math.abs(bank?.stats?.total_error_int))) {
                      badgeText = `+ ${bank?.stats?.total_success_int}`
                      badgeColor = 'green'
                    } else {
                      badgeText = bank?.stats?.total_error_int?.toString()
                      badgeColor = 'red'
                    };

                    return (
                      <li key={bank?.bank_id} className="customerDetail__bankAccount">
                        <Toggle
                          badgeText={badgeText}
                          badgeColor={badgeColor}
                          contentData={bank}
                        />
                      </li>
                    )
                  })
                }
              </ul>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerDetailView
