import { Box, Grid, Typography } from '@mui/material'
import Icon from '../Icon/Icon'

export interface NotificationProps {
  title: string
  description?: string
  icon?: string
}

const Notification = ({ title, description, icon = 'dialog' }: NotificationProps) => (
  <Box display='flex' alignItems='center' gap={2}>
    <Grid item xs={2} display='flex' alignItems='start' pl={1} sx={{ pt: '6px' }}>
      <Icon type={icon} />
    </Grid>
    <Grid item xs={10}>
      <Typography sx={{ fontSize: 14 }} fontWeight={600}>
        {title}
      </Typography>
      {
        description && (
          <Typography sx={{ fontSize: 13 }}>
            {description}
          </Typography>
        )
      }
    </Grid>
  </Box>
)

export default Notification
