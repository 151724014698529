import { useLocation, useParams } from 'react-router-dom'

const removeParamsFromPath = (path: string, params: Array<string | undefined>) => {
  let cleanPath = path
  params.forEach(param => {
    if (!param) return
    cleanPath = cleanPath.replace(`/${param}`, '')
  })
  return cleanPath
}

export const useBasePath = () => {
  const location = useLocation()
  const params = useParams<Record<string, string>>()
  return removeParamsFromPath(location.pathname, Object.values(params))
}
