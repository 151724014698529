import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'

import customersDataService from '../../../../services/payoutServices/customersDataService'
import getCustomersTableData from '../../../../utils/data/getCustomersTableData'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import ModalContext from '../../../../contexts/ModalContext'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import { useBasePath } from '../../../../hooks'
import type { TCustomerDetail } from '../../../../../typings/types'
import { useNavigate } from 'react-router-dom'

const useCustomers = () => {
  const basePath = useBasePath()
  const navigate = useNavigate()
  const companyData = useAppSelector((state) => state.authReducer.company)
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const [nativeData, setNativeData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [finderValue, setFinderValue] = useState<string>('')
  const [isLoadingCustomersTable, setIsLoadingCustomersTable] = useState<boolean>(true)

  const { setModalData } = useContext(ModalContext)

  const company_id = companyData?.length === 1 ? companyData[0].company_id : undefined // eslint-disable-line
  const timeZoneCode = companyData?.length && companyData.length > 0 ? companyData[0].time_zone_cod : ''

  /*
     * Función que maneja la petición de servicio que obtiene la data total de los Clientes del usuario
    */
  const getCustomersData = async (isRestarted: boolean, offset: number, searchValue: string) => {
    if (validPermittedServices('customersData', permissions)) {
      try {
        setIsLoadingCustomersTable(true)

        const response = await customersDataService({
          company_id,
          str_search: searchValue,
          limit_int: 50,
          offset_int: offset,
          orderby: 'created_dtm',
          orderdir_str: 'desc',
          user: reduxUser
        })
        const { data: responseData } = response
        const { data: currentNativeData } = responseData
        let newNativeData = currentNativeData

        if (!isRestarted) {
          newNativeData = nativeData?.concat(currentNativeData)
        };

        const customersTableData = getCustomersTableData(newNativeData)

        setNativeData(newNativeData)
        setData(customersTableData)
        setIsLoadingCustomersTable(false)
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'noAccess', service: 'customersData' })
    }
  }

  const handleScrollCustomersTableData = (event: any) => {
    const isBottom = (event?.target?.scrollHeight - event?.target?.scrollTop - 1) <= event?.target?.clientHeight

    if (isBottom && data?.body?.length >= offset + 50) {
      const newOffset = offset + 50

      setOffset(newOffset)
      setIsLoadingCustomersTable(true)

      getCustomersData(false, newOffset, finderValue) // eslint-disable-line
    };
  }

  const handleCustomersFinderValue = (event: any) => {
    const customersFinderValue = event?.target?.value

    setFinderValue(customersFinderValue)
  }

  const handleCustomersTableDataClick = (customerData: TCustomerDetail) => {
    const { companyId, externalCustomerId } = customerData
    if (!companyId || !externalCustomerId) return
    navigate(`/payout/customer-detail/${externalCustomerId}/${companyId}`)
  }

  useEffect(() => {
    setOffset(0)

    const table = document?.getElementsByClassName('wrapper_table')?.[0]
    table.scrollTop = 0

    getCustomersData(true, 0, finderValue) // eslint-disable-line
    }, [finderValue]); // eslint-disable-line

  return {
    // States
    isLoadingCustomersTable,
    timeZoneCode,
    data,

    // Functions States

    // Functions
    handleScrollCustomersTableData,
    handleCustomersFinderValue,
    handleCustomersTableDataClick
  }
}

export default useCustomers
