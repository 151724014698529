/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Badge from '../../../../components/Badge/Badge'
import Card from '../../../../components/Card/Card'
import Link from '../../../../components/Link/Link'

interface IInvoiceDetailViewProps {
    timeZoneCode: string
    invoiceData: any
    handleCreateTransactionReport: any
};

const InvoiceDetailView = ({
  timeZoneCode,
  invoiceData,
  handleCreateTransactionReport
}: IInvoiceDetailViewProps) => {
  return (
    <div className="view invoiceDetailView">
      <div className="view__header">
        <h1>Pago detalle</h1>
        <h2>Home - Dispersión - Cliente - Pago</h2>
      </div>
      <div className="view__body">
        <Card
          timeZoneCode={timeZoneCode}
          // eslint-disable-next-line
          children={
            <div className="invoicedetail">
              <div className="invoicedetail__principal">
                <div className="invoicedetail__principal_container">
                  <h3 className="invoicedetail__principal__title">Pago Detalle #{invoiceData?.invoiceId}</h3>
                  <Badge text={invoiceData?.status?.title} size="small" color={invoiceData?.status?.color} />
                  <div className="invoicedetail__principal__datalist">
                    <div className="invoicedetail__dataelement">
                      <h4>ID Externo:</h4>
                      <p>{invoiceData?.externalId}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>ID Dispersión:</h4>
                      <p>{invoiceData?.payoutId}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Fecha de registro:</h4>
                      <p>{invoiceData?.registerDate}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Fecha de movimiento:</h4>
                      <p>{invoiceData?.transactionDate}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Entidad financiera:</h4>
                      <p>{invoiceData?.bankName}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Tipo de cuenta:</h4>
                      <p>{invoiceData?.accountType}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Cuenta bancaria:</h4>
                      <ul>
                        <li><p>{invoiceData?.accountNumber}</p></li>
                        <li><p>{invoiceData?.accountCciNumber}</p></li>
                      </ul>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4># Operación bancaria:</h4>
                      <p>{invoiceData?.bankOperation}</p>
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Observación:</h4>
                      <p>{invoiceData?.observation}</p>
                    </div>
                    {

                      ['PAID', 'REJECTED']?.includes(invoiceData?.status?.name)
                        ? (
                          <div className="invoicedetail__dataelement">
                            <h4>Constancia Bancaria:</h4>
                            {
                              invoiceData?.bankCertificate
                                ? (
                                  <p><Link text="Constancia banco" url={invoiceData?.bankCertificate} /></p>
                                )
                                : (
                                  <></>
                                )
                            }
                            <div className="invoicedetail__transactionReport" onClick={handleCreateTransactionReport}>
                                                        Constancia OB
                            </div>
                          </div>
                        )
                        : <></>
                    }
                  </div>
                  <div className="invoicedetail__principal__total invoicedetail__dataelement">
                    <h4>Total</h4>
                    <p>{invoiceData?.totalAmount}</p>
                  </div>
                </div>
              </div>
              <div className="invoicedetail__client">
                <div className="invoicedetail__client_container">
                  <h3 className="invoicedetail__client__title">Cliente</h3>
                  <div className="invoicedetail__client__datalist">
                    <div className="invoicedetail__dataelement">
                      <h4>Id Cliente:</h4>
                      <Link url={`/payout/customer-detail/${invoiceData?.clientId}/${invoiceData?.companyId}`} text={invoiceData?.clientId} />
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Nombre:</h4>
                      <Link url={`/payout/customer-detail/${invoiceData?.clientId}/${invoiceData?.companyId}`} text={invoiceData?.clientName} />
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Tipo documento:</h4>
                      <Link url={`/payout/customer-detail/${invoiceData?.clientId}/${invoiceData?.companyId}`} text={invoiceData?.clientDocumentType} />
                    </div>
                    <div className="invoicedetail__dataelement">
                      <h4>Número documento:</h4>
                      <Link url={`/payout/customer-detail/${invoiceData?.clientId}/${invoiceData?.companyId}`} text={invoiceData?.clientDocumentNumber} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default InvoiceDetailView
