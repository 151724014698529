import Icon from '../Icon/Icon'

import { type DropdownOptionType } from '../../../typings/types'

interface IDropdownViewProps {
    className: string
    dropdownRef: React.LegacyRef<HTMLDivElement> | undefined
    options: any
    defaultOption: any
    value: string | undefined
    isDefaultOptionHide: boolean
    handleOpenDropdown: React.MouseEventHandler<HTMLDivElement> | undefined
    handleSelectOption: (option: DropdownOptionType) => void
    optionKey?: keyof DropdownOptionType
};

const DropdownView = ({
  className,
  dropdownRef,
  options,
  defaultOption,
  value,
  isDefaultOptionHide,
  optionKey,
  handleOpenDropdown,
  handleSelectOption
}: IDropdownViewProps) => {
  const filteredOptions = isDefaultOptionHide ? options : options?.concat(defaultOption)

  return (
    <div
      className={className}
      ref={dropdownRef}
      defaultValue={'default'}
      onClick={handleOpenDropdown}
    >
      <span className="dropdown__value">
        {
          options?.length > 1
            ? (
              <>
                {value}
                <Icon type="arrow" />
              </>
            )
            : (
              <>
                {options?.[0]?.title}
              </>
            )
        }
      </span>
      {
        options?.length > 1 && (
          <ul className="dropdown__options">
            {
              filteredOptions?.map((option: DropdownOptionType) => {
                const key = optionKey ? option[optionKey] : option?.id

                return (
                  <li
                    key={key}
                    id={option?.title}
                    className="dropdown__option"
                    onClick={() => { handleSelectOption(option) }}
                  >
                    {option?.title}
                  </li>
                )
              })
            }
          </ul>
        )
      }
    </div>
  )
}

export default DropdownView
