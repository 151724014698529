export const InitialInputsTouched = {
  username: false,
  password: false
}

export const InitialPayoutData = {
  company_id: 0,
  payout_id: '',
  total_rows_int: 0,
  total_dcm: 0,
  request_dtm: '',
  created_dtm: '',
  status_cod: '',
  name_str: '',
  events: {
    read: {
      total_rows_int: 0,
      total_dcm: 0,
      currency_cod: '',
      status_name_str: '',
      status_cod: ''
    },
    processed: {
      status_name_str: '',
      status_cod: '',
      currency_cod: '',
      total_rows_int: 0,
      total_dcm: 0,
      request_dtm: ''
    },
    rejected: {
      status_name_str: '',
      status_cod: '',
      currency_cod: '',
      total_rows_int: 0,
      total_dcm: 0,
      request_dtm: ''
    },
    paid: {
      status_name_str: '',
      status_cod: '',
      currency_cod: '',
      total_rows_int: 0,
      total_dcm: 0,
      request_dtm: ''
    },
    voided: {
      status_name_str: '',
      status_cod: '',
      currency_cod: '',
      total_rows_int: 0,
      total_dcm: 0,
      request_dtm: ''
    }
  },
  batch: {
    user_id: '',
    file: {
      file_cod: '',
      file_name_str: '',
      file_hash: '',
      file_path: '',
      request_dtm: ''
    }
  },
  status_history: {}
}

export const InitialUserLoginData = {
  username: '',
  password: ''
}

export const InitialNewPasswordData = {
  newPassword: '',
  confirmNewPassword: ''
}

export const InitialFilterData = {
  processStatus: 'all',
  calendar: [],
  nameId: '',
  companyId: 'all'
}

export const InitialInvoiceFilterData = {
  processStatus: '',
  calendar: [],
  nameId: '',
  companyId: ''
}

export const InitialTimelineItemData = {
  quantity: 0,
  total: 0,
  name: '',
  color: 'yellow',
  statusCode: 'PROCESSING'
}

export const InitialCalendarDateData = [
  '',
  ''
]

export const initialStatementFilterData = {
  calendar: [],
  companyId: 'all'
}
