import { createSlice } from '@reduxjs/toolkit'

interface User {
  user_id: string
  name_str: string
  email_str: string
  role_id: string
  route_index_str: string
  secret_key: string
}

export interface Company {
  company_id: string
  name_str: string
  time_zone_cod: string
  files: Array<{
    file_cod: string
    file_ext: string
  }>
}

export interface AuthState {
  user: User | null
  company: Company[] | null
  permissions: any
  components: any
  services: any
  sidebarMenuData: any
  servicesBySubMenu: any
  isAuthenticated: boolean
}

const storedUser = localStorage.getItem('user')
const parsedUser = storedUser ? JSON.parse(storedUser) : null

const storedCompany = localStorage.getItem('company')
const parsedCompany = storedCompany ? JSON.parse(storedCompany) : null

const storedPermissions = localStorage.getItem('permissions')
const parsedPermissions = storedPermissions ? JSON.parse(storedPermissions) : null

const storedServices = localStorage.getItem('services')
const parsedServices = storedServices ? JSON.parse(storedServices) : null

const storedServicesBySubMenu = localStorage.getItem('servicesBySubMenu')
const parsedServicesBySubMenu = storedServicesBySubMenu ? JSON.parse(storedServicesBySubMenu) : null

const storedComponents = localStorage.getItem('components')
const parsedComponents = storedComponents ? JSON.parse(storedComponents) : null

const storedSidebarMenuData = localStorage.getItem('sidebarMenuData')
const parsedSidebarMenuData = storedSidebarMenuData ? JSON.parse(storedSidebarMenuData) : null

const initialState: AuthState = {
  user: parsedUser,
  company: parsedCompany,
  permissions: parsedPermissions,
  services: parsedServices,
  components: parsedComponents,
  sidebarMenuData: parsedSidebarMenuData,
  servicesBySubMenu: parsedServicesBySubMenu,
  isAuthenticated: !!parsedUser
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload.user
      state.company = action.payload.company
      state.permissions = action.payload.permissions
      state.services = action.payload.services
      state.servicesBySubMenu = action.payload.servicesBySubMenu
      state.components = action.payload.components
      state.sidebarMenuData = action.payload.sidebarMenuData
      localStorage.setItem('user', JSON.stringify(state.user))
      localStorage.setItem('company', JSON.stringify(state.company))
      localStorage.setItem('permissions', JSON.stringify(state.permissions))
      localStorage.setItem('services', JSON.stringify(state.services))
      localStorage.setItem('servicesBySubMenu', JSON.stringify(state.servicesBySubMenu))
      localStorage.setItem('components', JSON.stringify(state.components))
      localStorage.setItem('sidebarMenuData', JSON.stringify(state.sidebarMenuData))
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
      localStorage.removeItem('user')
      localStorage.removeItem('company')
      localStorage.removeItem('permissions')
      localStorage.removeItem('services')
      localStorage.removeItem('servicesBySubMenu')
      localStorage.removeItem('components')
      localStorage.removeItem('sidebarMenuData')
    }
  }
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer
