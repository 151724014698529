import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '../store/hooks'
import signOutService from '../services/cognitoServices/signOutService'

export const ProtectedRoutes = () => {
  const isAuth = useAppSelector(state => state.authReducer.isAuthenticated)
  const companies = useAppSelector(state => state.authReducer.company)
  const isValidCompanies = companies && Array.isArray(companies)

  // const signOut = useCallback(async () => {
  //   await signOutService()
  //   localStorage.clear()
  //   window.location.href = '/'
  // }, [])

  // useEffect(() => {
  //   if (!isValidCompanies) {
  //     void signOut()
  //   }
  // }, [signOut, isValidCompanies])

  if (!isAuth) return <Navigate to='/login' />
  if (!isValidCompanies) {
    void signOutService()
    localStorage.clear()
    window.location.href = process.env.REACT_APP_COGNITO_LOGOUT_URL ?? ''
    return <></>
  }

  return <Outlet />
}
