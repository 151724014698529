import StatementView from './components/StatementView'

import useStatement from './hooks/useStatement'

import './Statement.css'

const Statement = () => {
  const {
    data,
    timeZoneCode,
    isLoadingStatementTable,
    tableRef,
    setIsOpenCalendar,
    handleScrollStatementTableData,
    handleStatementExportButton,
    filterData,
    handleFilterSubmit
  } = useStatement()

  /*
   * Renderizado de la vista Statement
   */
  return (
    <StatementView
      filterData={filterData}
      handleFilterSubmit={handleFilterSubmit}
      data={data}
      tableRef={tableRef}
      timeZoneCode={timeZoneCode}
      isLoadingStatementTable={isLoadingStatementTable}
      setIsOpenCalendar={setIsOpenCalendar}
      handleStatementExportButton={handleStatementExportButton}
      handleScrollStatementTableData={handleScrollStatementTableData}
    />
  )
}

export default Statement
