import BatchDispersionStateDetail from './BatchDispersionStateDetail/BatchDispersionStateDetail'

import banksData from '../../../../../constants/banksData'

import Card from '../../../../../components/Card/Card'
import BatchDispersionStateLoader from '../../../../../components/Loader/BatchDispersionStateLoader/BatchDispersionStateLoader'
import NavItem from '../../../../../components/NavItem/NavItem'
import Image from '../../../../../components/Image/Image'
import Button from '../../../../../components/Button/Button'
import Icon from '../../../../../components/Icon/Icon'
import Code from '../../../../../components/Code/Code'
import type { PayoutDataType, TBankSelected } from '../../../../../../typings/types'
import BatchDispersionStatusHistorySubtitle from './BatchDispersionStatusHistorySubtitle/BatchDispersionStatusHistorySubtitle'
// import { InitialPayoutData } from '../../../../../constants/initialData'

interface IBatchDispersionStateViewProps {
    timeZoneCode: string
    fileNameSelected: string
    // uploadFileIdSelected: string
    // payoutDataSelected: PayoutDataType
    bankSelected: TBankSelected
    processStatus: string
    transactionReportsOptions: any
    transactionReportsRef: any
    // currentPayout: PayoutDataType
    isLoading: boolean
    isProcess: boolean
    isOpenTransactionReports: boolean
    statusHistoryTimelineOpened: boolean
    selectedPayouts: Record<string, PayoutDataType>
    fetchedPayouts: Record<string, PayoutDataType>
    isFetchingPayout: boolean
    canViewStatusHistory: boolean
    handleClickSelectExport: React.MouseEventHandler<HTMLLIElement> | undefined
    handleClickSelectBank: (bankName: TBankSelected) => void
    handleClickTransactionReport: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleClickTransactionReportsBanks: (option: string) => void
    handleClickStatusHistoryTrigger: () => void
  };

const BatchDispersionStateView = ({
  timeZoneCode,
  fileNameSelected,
  // payoutDataSelected,
  bankSelected,
  // uploadFileIdSelected,
  // currentPayout,
  processStatus,
  transactionReportsOptions,
  transactionReportsRef,
  isLoading,
  isProcess,
  isOpenTransactionReports,
  statusHistoryTimelineOpened,
  fetchedPayouts,
  selectedPayouts,
  isFetchingPayout,
  canViewStatusHistory,
  handleClickSelectExport,
  handleClickSelectBank,
  handleClickTransactionReport,
  handleClickTransactionReportsBanks,
  handleClickStatusHistoryTrigger
}: IBatchDispersionStateViewProps) => {
  const selectedPayoutsIds = Object.keys(selectedPayouts)
  const selectedPayoutsSize = selectedPayoutsIds.length
  const hasSelectedPayouts = selectedPayoutsSize > 0
  const fetchedPayoutsIds = Object.keys(fetchedPayouts)
  const fetchedPayoutsSize = fetchedPayoutsIds.length
  const hasFetchedPayouts = fetchedPayoutsSize > 0
  const firstFetchedPayout = hasFetchedPayouts ? fetchedPayouts[fetchedPayoutsIds[0]] : undefined
  const selectedFileNames = hasSelectedPayouts ? Object.values(selectedPayouts).map(payout => payout.name_str).join(', ') : ''
  const payoutsOriginCode = firstFetchedPayout?.origin_cod // all grouped payouts have the same origin
  const payoutsStatusCode = firstFetchedPayout?.status_cod // all grouped payouts have the status code
  const availableBanks = payoutsOriginCode === 'API' ? banksData : banksData.filter(bank => bank.id === 'all')
  const isDone = payoutsStatusCode ? ['SUCCESS', 'VOIDED'].includes(payoutsStatusCode) : false
  const areAllPayoutsFetched = selectedPayoutsIds.map(id => fetchedPayouts[id]).every(Boolean)

  const banksNav = (
    <div className="batchdispersionstate">
      {
        availableBanks.map(bank => (
          <NavItem
            key={bank.name}
            name={bank.name}
            icon={
              <Image
                type={bank.typeLogo}
                width={bank.widthLogo}
                height={bank.heightLogo}
                alt={bank.alt}
              />
            }
            selected={bankSelected === bank.id}
            disabled={!hasSelectedPayouts}
            onClick={() => {
              if (!hasSelectedPayouts) return
              handleClickSelectBank(bank.id)
            }}
          />
        ))
      }
    </div>
  )

  const getBatchDispersionContent = () => {
    if (isFetchingPayout) return <BatchDispersionStateLoader />
    if (!hasSelectedPayouts) return null
    if (!areAllPayoutsFetched) return null
    return (
      <>
        <BatchDispersionStateDetail
          timeZoneCode={timeZoneCode}
          fetchedPayouts={fetchedPayouts}
          payoutsOriginCode={payoutsOriginCode}
          payoutsStatusCode={payoutsStatusCode}
          bankSelected={bankSelected}
        />
        <div
          className={`batchdispersionstate__transactionReports ${transactionReportsOptions?.length ? 'batchdispersionstate__transactionReports--hasOptions' : ''} ${isOpenTransactionReports ? 'batchdispersionstate__transactionReports--open' : ''}`}
          ref={transactionReportsRef}
        >
          {
            isDone && (
              <Button
                isFitContent
                title="PDF"
                text="Constancia de depósitos"
                icon={<Icon type="pdf"/>}
                onClick={handleClickTransactionReport}
              />
            )
          }
          {
            isOpenTransactionReports && (
              <ul className="batchdispersionstate__transactionReports__options">
                {
                  transactionReportsOptions?.map((option: any) => (
                    <li
                      key={option?.id}
                      className="batchdispersionstate__transactionReports__option"
                      onClick={() => { handleClickTransactionReportsBanks(option?.value) }}
                    >
                      {option?.name}
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
        <Code label={fetchedPayoutsSize > 1 ? 'IDs de proceso' : 'ID de proceso'} text={selectedPayoutsIds.join(', ')} />
      </>
    )
  }

  return (
    <Card
      key={selectedFileNames}
      title="Estado de dispersión"
      subtitle={
        !isFetchingPayout
          ? canViewStatusHistory && fetchedPayoutsSize === 1
            ? <BatchDispersionStatusHistorySubtitle
              fileName={fileNameSelected}
              opened={statusHistoryTimelineOpened}
              statusHistory={fetchedPayouts[fetchedPayoutsIds[0]].status_history}
              handleClickTrigger={handleClickStatusHistoryTrigger}
            />
            : selectedFileNames
          : null
      }
      payoutsOriginCode={payoutsOriginCode}
      payoutsStatusCode={payoutsStatusCode}
      timeZoneCode={timeZoneCode}
      isToolbarDisabled={!hasSelectedPayouts}
      isDispersing={payoutsStatusCode === 'DISPERSION'}
      hasToolbar={!isFetchingPayout}
      handleSelectOptionToolbarClick={handleClickSelectExport}
    >
      {!isFetchingPayout && areAllPayoutsFetched && banksNav}
      {getBatchDispersionContent()}
    </Card>
  )
}

export default BatchDispersionStateView
